<script>
  import {fade} from 'svelte/transition';
  import {navigate} from "svelte-routing"
  import Spinner from "svelte-spinner"
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import PublicImage from ".././../ui/PublicImage.svelte"
  import {createEventDispatcher} from "svelte";
  import {_} from '../../../store/i18n';
  import { toast } from '@zerodevx/svelte-toast'

  export let pageNumber = 1;
  export let inModal = false;
  let loading, errorMessage;
  let images = [];
  let query = "";
  let timeout = null;
  let meta = {
    page: 1,
    per_page: 32,
    total: 0
  };

  const dispatch = createEventDispatcher();

  $: if(query !== "") {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(function() {
      searchImage(1);
    }, 300);
  } else {
    pageNumber = parseInt(pageNumber);
    loadImages(pageNumber);
  }

  function handleImageClick(image) {
    if (inModal) {
      dispatch("select", image)
    } else {
      navigate("/image/view/" + image.id)
    }
  }

  function searchImage(page) {
    loading = true;
    meta.page = page;
    fetchApi(apiEndpoint + "/admin/image/search", {
      query: query,
      status: ["draft", "published"],
      page: meta.page,
      per_page: meta.per_page,
    }, "POST")
    .then(res => {
      if (res.status) {
        images = res.data;
        meta.total = res.meta.total;
      } else {
        errorMessage = res.message;
        toast.push(res.message, {
          theme: {
            '--toastBackground': '#F56565',
            '--toastProgressBackground': '#C53030'
          }
        });
      }
    })
    .finally(() => {
      loading = false;
    });
  }

  function loadImages(page) {
    loading = true;
    meta.page = parseInt(page);
    fetchApi(apiEndpoint + "/admin/image/get/list", {
      status: ["draft", "published"],
      page: meta.page,
      per_page: meta.per_page,
    }, "POST")
    .then(res => {
      if (res.status) {
        images = res.data;
        meta.total = res.meta.total;
        console.log("images", images);
      } else {
        errorMessage = res.message;
        toast.push(res.message, {
          theme: {
            '--toastBackground': '#F56565',
            '--toastProgressBackground': '#C53030'
          }
        });
      }
    })
    .catch(err => {
    })
    .finally(() => {
        loading = false;
    });
  }
</script>

<div class:content={!inModal} in:fade>
  {#if !inModal}
    <div class="card plr">
    <h1>{$_.t("image:Image List")}</h1>
  </div>
  {/if}
  <div class:card={!inModal}>
    <input class="full-width" bind:value={query} placeholder={$_.t("image:type image name")}>
    <div class="item-list grid">
      {#if loading}
        <Spinner />
      {/if}
      {#each images as image, i (image.id)}
        <div class="item column" on:click={() => handleImageClick(image)}>
          <div>
            <PublicImage uuid={image.uuid} size="1x1-m" />
          </div>
          <div class="title">{image.title}</div>
        </div>
      {/each}
    </div>
    {#if !loading && images.length === 0}
      <p>{$_.t("image:No images found!")}</p>
    {/if}
    <div class="list-pagination">
      {#if meta.page !== 1}
        <div class="chips" on:click={() => (query !== "") ? searchImage(meta.page-1) :  inModal ? loadImages(meta.page-1) : navigate("/image/list/page/"+(meta.page-1))}>{$_.t("pagination:Prev")}</div>
      {:else}
        <div class="chips inactive">{$_.t("pagination:Prev")}</div>
      {/if}
      {#if meta.total > (meta.page*meta.per_page)}
        <div class="chips" on:click={() => (query !== "") ? searchImage(meta.page+1) : inModal ? loadImages(meta.page+1) : navigate("/image/list/page/"+(meta.page+1))}>{$_.t("pagination:Next")}</div>
      {:else}
        <div class="chips inactive">{$_.t("pagination:Next")}</div>
      {/if}
      <div class="gray"><small>{$_.t("pagination:Page")}: {meta.page}</small></div>
    </div>
    <ErrorMessage errorMessage={errorMessage} />
  </div>
</div>
