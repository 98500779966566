<script>
  import {fade} from "svelte/transition"
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import PublicImage from "../../ui/PublicImage.svelte";
  import {navigate} from "svelte-routing";
  import Spinner from 'svelte-spinner';
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import {_} from '../../../store/i18n';

  let loading, errorMessage;
  let previewImage;
  let uploadFiles = [];
  let uploadTitle = "";

  function fileChange() {
    let reader = new FileReader();
    reader.onload = function (e) {
      previewImage = e.target.result;
    };
    reader.readAsDataURL(uploadFiles[0]);
  }

  function upload() {
    loading = true;
    const formData = new FormData();
    formData.append('title', uploadTitle);
    formData.append('file', uploadFiles[0]);
    fetchApi(apiEndpoint + "/admin/image/add", formData, "POST", null, null, true)
    .then(res => {
      if (res.status === true) {
        navigate("/image/list/page/1");
      } else {
        errorMessage = res.message;
      }
    })
    .finally(() => {
      loading = false;
    });
  }

</script>

<div class="content" id="page-image-add">
  <div class="card plr">
    <h1>{$_.t("image:Add New Image")}</h1>
  </div>
  <div class="card">
    <div class="horizontal flex-same" in:fade>
      <div class="photo-section">
        {#if previewImage}
          <img src={previewImage} alt="">
        {:else}
          <PublicImage size="1x1-m" uuid="" classAttr="preview"/>
        {/if}
      </div>
      <div class="upload-form">
        <label>{$_.t("image:Select image file")}
          <input type="file" bind:files={uploadFiles} on:change={() => fileChange()} disabled={loading}>
        </label>
        <label>{$_.t("image:Description")}
          <textarea bind:value={uploadTitle} rows="6" disabled={loading}></textarea>
        </label>
        <button class="pointer" class:green={uploadFiles.length !== 0}
                on:click={() => upload()}
                disabled="{uploadFiles.length === 0 || loading}">{$_.t("image:Upload")}</button>
      </div>
    </div>
    {#if loading}
      <Spinner />
    {/if}
    <ErrorMessage errorMessage={errorMessage} />
  </div>
</div>
