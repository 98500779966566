<script>
  import {fade} from 'svelte/transition';
  import {onMount} from "svelte";
  import Spinner from "svelte-spinner"
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import {navigate} from "svelte-routing"
  import {copyObject} from "../../../lib/obj";
  import {user} from "../../../store/user"
  import { toast } from '@zerodevx/svelte-toast'
  import {_} from '../../../store/i18n';
  import {moveInArray} from "../../../lib/array";

  export let id;

  let loading, errorMessage;
  let ingredient;

  onMount(() => {
    loadIngredient();
  });

  function loadIngredient() {
    loading = true;
    fetchApi(apiEndpoint + "/admin/ingredient/get/single", {id: parseInt(id)}, "POST")
    .then(res => {
      if (res.status) {
        ingredient = res.data;
        console.log("ingredient", ingredient);
      } else {
        errorMessage = res.message;
        toast.push(res.message, {
          theme: {
            '--toastBackground': '#F56565',
            '--toastProgressBackground': '#C53030'
          }
        });
      }
    })
    .catch(err => {
    })
    .finally(() => {
        loading = false;
    });
    }

  function saveIngredient() {
    loading = true;
    let tempPage = copyObject(ingredient);
    tempPage.status = status;
    fetchApi(apiEndpoint + "/admin/ingredient/edit", tempPage, "PUT")
        .then(res => {
          if (res.status) {
            navigate("/ingredient/view/"+res.data.id);
          } else {
            errorMessage = res.message;
            toast.push(res.message, {
              theme: {
                '--toastBackground': '#F56565',
                '--toastProgressBackground': '#C53030'
              }
            });
          }
        })
        .finally(() => {
          loading = false;
        });
  }

  function addIngredient() {
    ingredient.units = [...ingredient.units, {
      title: "unit",
      in_gram: 0
    }];
  }

  function removeIngredient(index) {
    ingredient.units = ingredient.units.filter((item, i) => i !== index);
  }

  function move(arr, old_index, new_index) {
    if (new_index < 0 || new_index > arr.length-1) {
      return false;
    }
    arr = moveInArray(arr, old_index, new_index);
    ingredient = ingredient;
    // recipe.set($recipe);
  }

</script>

<style>
  small {
    color: #649e0a;
    margin-left: 0.5em;
  }
</style>

<div class="content" in:fade>
  <div class="card">
    {#if ingredient}
      <h1>{ingredient.name}</h1>
      {#if loading}
        <Spinner />
      {:else}
        {#if $user.is('admin')}
        <div class="edit-form card">
          <label>Rename
            <input type="text" bind:value={ingredient.rename}>
          </label>
          <h4>Units</h4>
          {#each ingredient.units as unit,i (i)}
            <div class="flex-same horizontal">
              <input type="text" bind:value={ingredient.units[i].title}  style="flex: 4">
              <input type="number" bind:value={ingredient.units[i].in_gram} style="flex: 4">
              {#if i-1 >= 0}
                <div class="text-center pointer" on:click={() => move(ingredient.units, i, i-1)}>
                  &#8593;
                </div>
              {:else}
                <div></div>
              {/if}
              {#if i+1 < ingredient.units.length}
                <div class="text-center pointer" on:click={() => move(ingredient.units, i, i+1)}>
                  &#8595;
                </div>
              {:else}
                <div></div>
              {/if}
              <button on:click={() => removeIngredient(i)} class="pointer">x</button>
            </div>
          {/each}
          <button class="pointer" on:click={addIngredient}>add unit</button>
          <button class="light-green bold pointer" on:click={saveIngredient} disabled={loading}>Save</button>
        </div>
        {/if}
        <h4>Units</h4>
        <div class="item-list">
          {#each ingredient.units as unit}
            <p>{unit.title} <small>{unit.in_gram}g</small></p>
          {/each}
        </div>
      {/if}
      <ErrorMessage errorMessage={errorMessage} />
    {/if}
  </div>
</div>