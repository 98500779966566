<script>
  import {fade} from 'svelte/transition';
  import {onMount} from "svelte";
  import Spinner from "svelte-spinner"
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import {navigate} from "svelte-routing"
  import {_} from '../../../store/i18n';

  let loading, errorMessage;
  let ingredients = [];
  let query = "";
  let timeout = null;
  let rawName = "";
  let rawId = "";

  $: if(query.length > 0) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(function() {
      searchIngredient();
    }, 800);
  }

  function searchIngredient() {
    loading = true;
    fetchApi(apiEndpoint + "/admin/ingredient/search_source", {query: query}, "POST")
    .then(res => {
      if (res.status) {
        ingredients = res.data;
      } else {
        errorMessage = res.message;
      }
    })
    .catch(err => {
    })
    .finally(() => {
        loading = false;
    });
  }

  function saveFromSource(name) {
    console.log("@@@@", name);
    loading = true;
    fetchApi(apiEndpoint + "/admin/ingredient/add_from_source", {name: name.t}, "POST")
    .then(res => {
      if (res.status) {
        // ingredients = res.data;
        // navigate("/ingredient/list")
        query = "";
        ingredients = [];
      } else {
        errorMessage = res.message;
      }
    })
    .catch(err => {
    })
    .finally(() => {
        loading = false;
    });
  }
</script>

<div class="content" in:fade>
  <div class="card plr">
    <h1>{$_.t("ingredient:Add Nutrition")}</h1>
  </div>
  <div class="card">
    <input bind:value={query} placeholder="{$_.t('ingredient:type ingredient name')}">
    {#if loading}
      <Spinner />
    {:else}
      {#if query === ""}
          <p>{$_.t("ingredient:Start typing ingredient name")}</p>
      {/if}
      {#if ingredients}
        <div class="item-list">
          {#each ingredients as ingredient, i (i)}
            <div class="item" on:click={() => saveFromSource(ingredient)}>{ingredient.t[0]}</div>
          {/each}
        </div>
      {:else}
        <p>No ingredients found!</p>
      {/if}
    {/if}
    <input bind:value={rawName} placeholder="{$_.t('ingredient:name')}">
    <input bind:value={rawId} placeholder="{$_.t('ingredient:id')}">
    <button type="submit" on:click={() => saveFromSource({"t": [rawName,rawId]})}>{$_.t("ingredient:save")}</button>
    <ErrorMessage errorMessage={errorMessage} />
  </div>
</div>