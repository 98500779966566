<script>
  import {fade} from 'svelte/transition';
  import {onMount} from "svelte";
  import Spinner from "svelte-spinner"
  import {apiEndpoint, fetchApi} from "../../lib/api";
  import ErrorMessage from "../ui/ErrorMessage.svelte";
  import {navigate} from "svelte-routing"
  import {_} from '../../store/i18n';
  import {addAuthHelper, user} from "../../store/user"
  import { toast } from '@zerodevx/svelte-toast'

  export let id;
  let loading, errorMessage;
  let userName = "";
  let password = "";

  function login() {
    loading = true;
    fetchApi(apiEndpoint + "/auth/login", {
      user_name: userName,
      password: password,
    }, "POST")
    .then(res => {
      console.log(res);
      if (res.status) {
        res.data = addAuthHelper(res.data);
        user.set(res.data);
        navigate("/");
      } else {
        // errorMessage = res.message
        toast.push(res.message, {
          theme: {
            '--toastBackground': '#F56565',
            '--toastProgressBackground': '#C53030'
          }
        });
      }
    })
    .finally(() => {
      loading = false;
    });
  }
</script>

<div class="content" id="page-login" in:fade>
  <div class="box">
    <div class="card plr">
      <h1>Login</h1>
    </div>
    <form class="card" on:submit|preventDefault={login}>
      <label>{$_.t("recipe:User Name")}
        <input type="text" bind:value={userName} placeholder="">
      </label>
      <label>{$_.t("recipe:Password")}
        <input type="password" bind:value={password} placeholder="">
      </label>
      <br />
      <button type="submit">{$_.t("recipe:Login")}</button>
      {#if loading}
        <Spinner />
      {/if}
    </form>
    <ErrorMessage errorMessage={errorMessage} />
  </div>
</div>