<script>
  import {recipe} from "../../../../store/recipe/add";
  import {_} from '../../../../store/i18n';
  import {user} from "../../../../store/user";

  function canEdit() {
    if (
      !$user.is('admin')
      && !$user.is('recipe_editor')
      && !$user.is('author')
    ) {
      return true;
    }
    return false;
  }

</script>

<div class="cook-section card">
  <label>{$_.t("recipe:Prepare Time")}
    <input type="number" bind:value={$recipe.prepare_time} placeholder="0" disabled={canEdit()}>
  </label>

  <label>{$_.t("recipe:Cook Time")}
    <input type="number" bind:value={$recipe.cook_time} placeholder="0" disabled={canEdit()}>
  </label>

  <label>{$_.t("recipe:Yield")}
    <input type="text" bind:value={$recipe.yield} placeholder="2 cookies" disabled={canEdit()}>
  </label>

  <label>{$_.t("recipe:Serving")}
    <input type="number" min="1" bind:value={$recipe.serving} placeholder="1" disabled={canEdit()}>
  </label>
  {#if $user.is('admin')}
    <label>{$_.t("recipe:Video")}
      <input bind:value={$recipe.video.uuid}>
    </label>
  {/if}
</div>