<script>
  import { fade } from "svelte/transition";
  import { createEventDispatcher } from "svelte";

  export let isOpen = false;
  const dispatch = createEventDispatcher();

  function close() {
    isOpen = false;
    dispatch("closed", {
      text: "modal closed!"
    });
  }
</script>

{#if isOpen}
  <div
    transition:fade={{ duration: 200 }}
    class="modal backdrop"
    on:click|self={close}>
    <div class="box">
      <slot name="header" />
      <slot name="main" />
      <slot name="footer" />
    </div>
  </div>
{/if}
