<script>
  import {fade} from 'svelte/transition';
  import moment from "moment";
  import {onMount} from "svelte";
  import Spinner from "svelte-spinner"
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import {navigate} from "svelte-routing"

  let loading, errorMessage;
  let pages = [];

  onMount(() => {
    loadRecipes();
  });

  function loadRecipes() {
    loading = true;
    fetchApi(apiEndpoint + "/admin/page/get/list", {
      status: ["deleted"],
    }, "POST")
    .then(res => {
      if (res.status) {
        pages = res.data;
        console.log("pages", pages);
      } else {
        errorMessage = res.message;
      }
    })
    .catch(err => {
    })
    .finally(() => {
      loading = false;
    });
  }
</script>

<div class="content" in:fade>
  <div class="card plr">
    <h1>Page Trash List</h1>
  </div>
  <div class="card">
    {#if loading}
      <Spinner />
    {:else}
      {#if pages.length > 0}
      <div class="item-list">
        <div class="item heading">
          <div>Title</div>
<!--          <div>Deleted</div>-->
        </div>
        {#each pages as page, i (page.id)}
          <div class="item" on:click={() => navigate("/page/trash/edit/"+page.id)}>
            <div>{page.title}</div>
<!--            <div>{moment(page.deleted_at).format("D, M, YYYY, hh:mm:ss")}</div>-->
          </div>
        {/each}
      </div>
      {:else}
        <p>No garbage yet!</p>
      {/if}
    {/if}
    <ErrorMessage errorMessage={errorMessage} />
  </div>
</div>