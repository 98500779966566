<script>
  import {fade} from 'svelte/transition';
  import {onMount} from "svelte";
  import Spinner from "svelte-spinner"
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import {navigate} from "svelte-routing"
  import PublicImage from ".././../ui/PublicImage.svelte"
  import {_} from '../../../store/i18n';

  let loading, errorMessage;
  let images = [];

  onMount(() => {
    loadImages();
  });

  function loadImages() {
    loading = true;
    fetchApi(apiEndpoint + "/admin/image/get/list", {
      status: ["deleted"],
    }, "POST")
    .then(res => {
      if (res.status) {
        images = res.data;
        console.log("images", images);
      } else {
        errorMessage = res.message;
      }
    })
    .finally(() => {
      loading = false;
    });
  }
</script>

<div class="content" in:fade>
  <div class="card plr">
    <h1>{$_.t("image:Image Trash List")}</h1>
  </div>
  <div class="card">
    <div class="item-list grid" in:fade>
      {#if loading}
        <Spinner />
      {/if}
      {#each images as image, i (image.id)}
        <div class="item column" on:click={() => navigate("/image/view/" + image.id)}>
          <PublicImage uuid={image.uuid} size="1x1-m" />
          <div class="title">{image.title}</div>
        </div>
      {/each}
    </div>
    {#if !loading && images.length === 0}
        <p>{$_.t("image:No images found!")}</p>
    {/if}
      <ErrorMessage errorMessage={errorMessage} />
  </div>
</div>