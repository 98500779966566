<script>
  import {fade} from 'svelte/transition';
  import {onMount} from "svelte";
  import moment from "moment";
  import Spinner from "svelte-spinner"
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import {navigate} from "svelte-routing"
  import {_} from '../../../store/i18n';

  let loading, errorMessage;
  let pages = [];
  let nullDate = "0001-01-01T00:00:00Z";
  let meta = {
    page: 1,
    per_page: 10,
    total: 0
  };

  onMount(() => {
    loadPages(1);
  });

  function loadPages(page) {
    loading = true;
    meta.page = page;
    fetchApi(apiEndpoint + "/admin/page/get/list", {
      status: ["draft", "published"],
      page: meta.page,
      per_page: meta.per_page,
    }, "POST")
    .then(res => {
      if (res.status) {
        pages = res.data;
        meta.total = res.meta.total;
      } else {
        errorMessage = res.message;
        toast.push(res.message, {
          theme: {
            '--toastBackground': '#F56565',
            '--toastProgressBackground': '#C53030'
          }
        })
      }
    })
    .finally(() => {
      loading = false;
    });
  }

  function getDate(d) {
    if (d === nullDate) {
      return "-";
    }
    return moment(d).format("D, M, YYYY  hh:mm");
  }
</script>

<div class="content" in:fade>
    <div class="card plr">
      <h1>{$_.t("page:Page List")}</h1>
    </div>
    <div class="card">
    {#if loading}
      <Spinner />
    {:else}
      {#if pages.length > 0}
      <div class="item-list">
        <div class="item heading">
          <div class="title">Title</div>
          <div>Status</div>
          <div>Published</div>
        </div>
        {#each pages as page, i (page.id)}
          <div class="item" on:click={() => navigate("/page/list/edit/"+page.id)}>
            <div class="title bold">{page.title}</div>
            <div>
              <div class="chips {page.status}">{page.status}</div>
            </div>
            <div>{getDate(page.published_at)}</div>
          </div>
        {/each}
      </div>
      {:else}
        <p>No page yet!</p>
      {/if}
    {/if}
    <div class="list-pagination">
      {#if meta.page !== 1}
        <div class="chips" on:click={() => loadPages(meta.page-1)}>{$_.t("pagination:Prev")}</div>
      {:else}
          <div class="chips inactive">Prev</div>
      {/if}
      {#if meta.total > (meta.page*meta.per_page)}
        <div class="chips" on:click={() => loadPages(meta.page+1)}>{$_.t("pagination:Next")}</div>
      {/if}
    </div>
    <ErrorMessage errorMessage={errorMessage} />
  </div>
</div>