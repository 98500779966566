<script>
  import {recipe} from "../../../../store/recipe/add";
  import {apiEndpoint, fetchApi} from "../../../../lib/api";
  import {onMount} from "svelte";
  import Select from 'svelte-select';
  import {_} from '../../../../store/i18n';
  import {user} from "../../../../store/user";

  let keywords = [];
  let meal = undefined;

  $: console.log("meal  ====", meal);

  onMount(() => {
    loadKeywords();
  });

  function loadKeywords() {
    fetchApi(apiEndpoint + "/admin/keyword/get/list", {}, "POST")
    .then(res => {
      if (res.status) {
        res.data.map((keyword) => {
          if (!keywords[keyword.type]) {
            keywords[keyword.type] = [];
          }
          keywords[keyword.type].push({
            label: keyword.title,
            value: keyword.id,
            id: keyword.id,
          });
        });
        console.log("keywords", keywords);
      } else {
      }
    })
    .catch(err => {
    })
    .finally(() => {
    });
  }
  function canEdit() {
      if (
          $user.is('admin')
          || $user.is('recipe_editor')
          || $user.is('author')
      ) {
          return true;
      }
      return false;
  }
</script>

<div class="meta-section card">
  <label>{$_.t("recipe:Dish Type")}
    <Select isDisabled={!canEdit()} isMulti={true} isClearable={false} items={keywords['dish_type']} bind:selectedValue={$recipe.dish_type}/>
  </label>

  <label>{$_.t("recipe:Meal")}
    <Select isDisabled={!canEdit()} isMulti={true} isClearable={false} items={keywords['meal']} bind:selectedValue={$recipe.meal}/>
  </label>

  <label>{$_.t("recipe:Occasion")}
    <Select isDisabled={!canEdit()} isMulti={true} isClearable={false} items={keywords['occasion']} bind:selectedValue={$recipe.occasion}/>
  </label>

  <label>{$_.t("recipe:Cuisine")}
    <Select isDisabled={!canEdit()} isMulti={true} isClearable={false} items={keywords['cuisine']} bind:selectedValue={$recipe.cuisine}/>
  </label>

  <label>{$_.t("recipe:Season")}
    <Select isDisabled={!canEdit()} isMulti={true} isClearable={false} items={keywords['season']} bind:selectedValue={$recipe.season}/>
  </label>

  <label>{$_.t("recipe:Diet")}
    <Select isDisabled={!canEdit()} isMulti={true} isClearable={false} items={keywords['diet']} bind:selectedValue={$recipe.diet}/>
  </label>

  <label>{$_.t("recipe:Method")}
    <Select isDisabled={!canEdit()} isMulti={true} isClearable={false} items={keywords['cooking_method']} bind:selectedValue={$recipe.cooking_method}/>
  </label>

  <label>{$_.t("recipe:Difficulty")}
    <Select isDisabled={!canEdit()} isMulti={true} isClearable={false} items={keywords['difficulty']} bind:selectedValue={$recipe.difficulty}/>
  </label>

  <label>{$_.t("recipe:Course")}
    <Select isDisabled={!canEdit()} isMulti={true} isClearable={false} items={keywords['course']} bind:selectedValue={$recipe.course}/>
  </label>
</div>