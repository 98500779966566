<script>
  import {fade} from "svelte/transition"
  import {recipe} from "../../../../store/recipe/add";
  import {ingredientModel} from "../model"
  import {copyObject} from "../../../../lib/obj";
  import {apiEndpoint, fetchApi} from "../../../../lib/api";
  import Select from 'svelte-select';
  import {moveInArray} from "../../../../lib/array";
  import {_} from '../../../../store/i18n';
  import {user} from "../../../../store/user";

  const optionIdentifier = 'id';
  const getRecipeOptionLabel = (option) => option.title;
  const getRecipeSelectionLabel = (option) => option.title;
  const getIngredientOptionLabel = (option) => option.rename;
  const getIngredientSelectionLabel = (option) => option.rename;

  function getIngredients(filterText) {
    filterText = filterText ? filterText : '';
    return new Promise((resolve, reject) => {
      fetchApi(apiEndpoint + "/admin/ingredient/search", {
        query: filterText,
      }, "POST")
      .then(res => {
        if (res.status) {
          if (res.data == null) {
            resolve([]);
            return
          }
          resolve(res.data);
        } else {
          reject()
        }
      })
      .catch(err => {
        reject()
      })
      .finally(() => {
      });
    });
  }

  function getRecipes(filterText) {
    filterText = filterText ? filterText : '';
    return new Promise((resolve, reject) => {
      fetchApi(apiEndpoint + "/admin/recipe/search", {
        query: filterText,
        status: [
          "draft",
          "recipe_review",
          "translate",
          "translate_review",
          "pending",
          "published",
        ],
        page: 1,
        per_page: 5,
      }, "POST")
          .then(res => {
            if (res.status) {
              resolve(res.data);
            } else {
              reject()
            }
          })
          .catch(err => {
            reject()
          })
          .finally(() => {
          });
    });
  }

  function ingredientChange(event, i) {
    console.log("ingredientChange");
    console.log("$recipe.ingredients[i]", $recipe.ingredients[i]);
    console.log("event", event);
    console.log("i", i);
    $recipe.ingredients[i]['ingredient_id'] = event.detail.id;
    // $recipe.ingredients[i].rename = $recipe.ingredients[i].ingredient.name;
  }

  function linkedRecipeChange(event, i) {
    console.log("linkedRecipeChange");
    console.log("$recipe.ingredients[i]", $recipe.ingredients[i]);
    console.log("event", event);
    console.log("i", i);
    $recipe.ingredients[i]['linked_recipe_id'] = event.detail.id;
    // $recipe.ingredients[i].rename = $recipe.ingredients[i].linked_recipe.title;
    $recipe.ingredients[i].amount = $recipe.ingredients[i].linked_recipe.in_grams;
  }

	function remove(index) {
    $recipe.ingredients = $recipe.ingredients.filter((item, i) => i !== index);
    recipe.set($recipe);
  }

	function addNew(type) {
    let item = copyObject(ingredientModel);
    item.type = type;
    if (!$recipe.ingredients) {
      $recipe.ingredients = []
    }
    $recipe.ingredients.push(item);
    recipe.set($recipe);
  }

  function move(arr, old_index, new_index) {
      if (new_index < 0 || new_index > arr.length-1) {
          return false;
      }
      arr = moveInArray(arr, old_index, new_index);
      recipe.set($recipe);
  }

  function measureChange(i) {
    $recipe.ingredients[i].measure_rename = $recipe.ingredients[i].measure;
  }

  function canEdit() {
      if (
          $user.is('admin')
          || $user.is('recipe_editor')
          || $user.is('author')
      ) {
          return true;
      }
      return false;
  }
</script>

<style>
  .themed {
    --height: 35px;
  }
</style>

<div class="ingredients-section card">
  <h3 class="bordered">{$_.t("recipe:Ingredients")}</h3>
  <div class="ingredients">
  {#if $recipe.ingredients && $recipe.ingredients.length > 0}
    {#each $recipe.ingredients as ingredient,i (i)}
      <div class="ingredient themed" in:fade>
        {#if canEdit()}
          <div class="sort">
            {#if i-1 >= 0}
              <div on:click={() => move($recipe.ingredients, i, i-1)}>
                {#if $_.dir() === "rtl"}&#8594;{:else}&#8592;{/if}
              </div>
            {:else}
              <div></div>
            {/if}
            <div on:click={() => remove(i)}>X</div>
            {#if i+1 < $recipe.ingredients.length}
              <div on:click={() => move($recipe.ingredients, i, i+1)}>
                {#if $_.dir() === "rtl"}&#8592;{:else}&#8594;{/if}
              </div>
            {:else}
              <div></div>
            {/if}
          </div>
        {/if}
        {#if $recipe.ingredients[i].type === "recipe"}
          <Select loadOptions={getRecipes} optionIdentifier={optionIdentifier}
                  getSelectionLabel={getRecipeSelectionLabel}
                  getOptionLabel={getRecipeOptionLabel}
                  isClearable={false}
                  bind:selectedValue={$recipe.ingredients[i].linked_recipe}
                  on:select={(selected) =>  linkedRecipeChange(selected, i)}
                  isDisabled={!canEdit()}
                  placeholder="Search for recipe"/>
        {:else}
          <Select loadOptions={getIngredients} optionIdentifier={optionIdentifier}
                  getSelectionLabel={getIngredientSelectionLabel}
                  getOptionLabel={getIngredientOptionLabel}
                  isClearable={false}
                  bind:selectedValue={$recipe.ingredients[i].ingredient}
                  on:select={(selected) =>  ingredientChange(selected, i)}
                  isDisabled={!canEdit()}
                  placeholder="Search for ingredient"/>
        {/if}
<!--        {#if $recipe.ingredients[i].ingredient_id || $recipe.ingredients[i].linked_recipe_id}-->
        <input type="text" bind:value={$recipe.ingredients[i].rename} placeholder="Rename">
        {#if $recipe.original && $recipe.original.ingredients && $recipe.original.ingredients[i]}<div class="translate">{$recipe.original.ingredients[i].rename}</div>{/if}
        {#if $recipe.translate && $recipe.translate.ingredients && $recipe.translate.ingredients[i]}<div class="translate">{$recipe.translate.ingredients[i].rename}</div>{/if}

        <input type="number" min="1" bind:value={$recipe.ingredients[i].amount} placeholder="0" disabled={!canEdit()}>
          <select bind:value={$recipe.ingredients[i].measure} on:change={() => measureChange(i)} disabled={!canEdit()}>
<!--            {$recipe.ingredients[i].ingredient}-->
            {#if $recipe.ingredients[i] && $recipe.ingredients[i].ingredient_id && $recipe.ingredients[i].type === "ingredient"}
              {#each $recipe.ingredients[i].ingredient.units as unit}
                <option value={unit.title}>{unit.title}</option>
              {/each}
            {/if}
            <option value="gram">gram</option>
          </select>
          <input type="text" bind:value={$recipe.ingredients[i].measure_rename} placeholder="Measure Rename">
          {#if $recipe.original && $recipe.original.ingredients && $recipe.original.ingredients[i]}<div class="translate">{$recipe.original.ingredients[i].measure_rename}</div>{/if}
          {#if $recipe.translate && $recipe.translate.ingredients && $recipe.translate.ingredients[i]}<div class="translate">{$recipe.translate.ingredients[i].measure_rename}</div>{/if}
          <label class="gray">
            <input type=checkbox bind:checked={$recipe.ingredients[i].is_optional} disabled={!canEdit()}> {$_.t("recipe:Optional")}
          </label>
<!--        {/if}-->
      </div>
    {/each}
  {/if}
  {#if canEdit()}
    <div>
      <button class="pointer add" on:click={() => addNew("ingredient")}>+ {$_.t("recipe:Ingredient")}</button>
      <button class="pointer add" on:click={() => addNew("recipe")}>+ {$_.t("recipe:Recipe")}</button>
    </div>
  {/if}
  </div>
</div>