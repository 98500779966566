<script>
  import {fade} from "svelte/transition"
  import {recipe} from "../../../../store/recipe/add";
  import {instructionModel} from "../model"
  import {copyObject} from "../../../../lib/obj";
  import {createEventDispatcher, onMount} from "svelte";
  import PublicImage from "../../../ui/PublicImage.svelte";
  import {moveInArray} from "../../../../lib/array";
  import {_} from '../../../../store/i18n';
  import {user} from "../../../../store/user";

  const dispatch = createEventDispatcher();

  function addNew() {
    let item = copyObject(instructionModel);
    if (!$recipe.instructions) {
      $recipe.instructions = []
    }
    $recipe.instructions.push(item);
    recipe.set($recipe);
  }

  function remove(index) {
    $recipe.instructions = $recipe.instructions.filter((item, i) => i !== index);
    recipe.set($recipe);
  }

  function openUploadModal(target) {
    if (!canEdit()) {
      return;
    }
    console.log("instruction openUploadModal");
    console.log("target", target);
    dispatch("upload", {
      object: target,
      field: "image",
      index: null,
    })
  }

  function move(arr, old_index, new_index) {
    if (new_index < 0 || new_index > arr.length-1) {
      return false;
    }
    arr = moveInArray(arr, old_index, new_index);
    recipe.set($recipe);
  }

  function canEdit() {
      if (
          $user.is('admin')
          || $user.is('recipe_editor')
          || $user.is('author')
      ) {
          return true;
      }
      return false;
  }
</script>

<div class="instructions-section card">
  <h3 class="bordered">{$_.t("recipe:Instructions")}</h3>
  <div class="instructions">
    {#if $recipe.instructions}
    {#each $recipe.instructions as instruction,i (i)}
      <div class="instruction" in:fade>
    		{#if canEdit()}
          <div class="sort">
            {#if i-1 >= 0}
              <div on:click={() => move($recipe.instructions, i, i-1)}>
                {#if $_.dir() === "rtl"}&#8594;{:else}&#8592;{/if}
              </div>
            {:else}
              <div></div>
            {/if}
            <div on:click={() => remove(i)}>X</div>
            {#if i+1 < $recipe.instructions.length}
              <div on:click={() => move($recipe.instructions, i, i+1)}>
                {#if $_.dir() === "rtl"}&#8592;{:else}&#8594;{/if}
              </div>
            {:else}
              <div></div>
            {/if}
          </div>
        {/if}
        <div class="upload-open" on:click={() => openUploadModal($recipe.instructions[i])}>
          {#if $recipe.instructions[i].image && $recipe.instructions[i].image.uuid !== ""}
            <PublicImage size="1x1-m" uuid={$recipe.instructions[i].image.uuid} classAttr="preview"/>
          {:else}
            <img src="/build/img/def.png" alt="upload">
          {/if}
        </div>
        <input bind:value={$recipe.instructions[i].name} placeholder="Name">
        {#if $recipe.original && $recipe.original.instructions && $recipe.original.instructions[i]}<div class="translate">{$recipe.original.instructions[i].name}</div>{/if}
        {#if $recipe.translate && $recipe.translate.instructions && $recipe.translate.instructions[i]}<div class="translate">{$recipe.translate.instructions[i].name}</div>{/if}
        <textarea bind:value={$recipe.instructions[i].text} placeholder="Text" rows="8"></textarea>
        {#if $recipe.original && $recipe.original.instructions && $recipe.original.instructions[i]}<div class="translate">{$recipe.original.instructions[i].text}</div>{/if}
        {#if $recipe.translate && $recipe.translate.instructions && $recipe.translate.instructions[i]}<div class="translate">{$recipe.translate.instructions[i].text}</div>{/if}
      </div>
    {/each}
    {/if}
		{#if canEdit()}
      <div>
        <button class="pointer add" on:click={() => addNew()}>+</button>
      </div>
    {/if}
  </div>
</div>