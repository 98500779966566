<script>
    import {fade} from 'svelte/transition';
    import {addAuthHelper, user} from "../../store/user";
    import {navigate} from "svelte-routing";

    setTimeout(function () {
        let userModel = {token: ""};
        addAuthHelper(userModel);
        user.set(userModel);
        navigate("/login");
    }, 100)

</script>
