<script>
	import {recipe} from "../../../../store/recipe/add";
	import {createEventDispatcher} from "svelte";
	import PublicImage from "../../../ui/PublicImage.svelte";
	import Actions from "./Actions.svelte";
	import {_} from '../../../../store/i18n';
	import {user} from "../../../../store/user";
	import { publicStorage } from "../../../../lib/api";

	const dispatch = createEventDispatcher();

	function openUploadModal() {
		if ($user.is('admin') || $user.is('recipe_editor') || $user.is('author')) {
			dispatch("upload", {
				object: $recipe,
				field: "images",
				index: 0,
			})
		}
	}
</script>

<div class="photo-section card">
	<label>{$_.t("recipe:Main Image")}</label>
	<div class="upload-open" on:click={openUploadModal}>
		{#if $recipe.images && $recipe.images[0] && $recipe.images[0].uuid !== ""}
			<PublicImage size="1x1-m" uuid={$recipe.images[0].uuid} classAttr="preview"/>
			{#if $user.is('admin')}
				<a on:click|stopPropagation target="_blank" href="{publicStorage}/image/{$recipe.images[0].uuid.substring(0,2)}/{$recipe.images[0].uuid.substring(2,4)}/{$recipe.images[0].uuid}-xl.jpg">
					{$_.t("recipe:xLarge")}
				</a>
				<a on:click|stopPropagation target="_blank" href="{publicStorage}_bk/image/{$recipe.images[0].uuid.substring(0,2)}/{$recipe.images[0].uuid.substring(2,4)}/{$recipe.images[0].uuid}{$recipe.images[0].extension}">
					{$_.t("recipe:Backup")}
				</a>
			{/if}
		{:else}
			<img src="/build/img/def.png" alt="upload">
		{/if}
	</div>
	<Actions status={$recipe.status} on:reload/>
</div>