<script>
    import { Link } from "svelte-routing";

    export let to = "";
    export let classAttr = "";

    function getProps({ location, href, isPartiallyCurrent, isCurrent }) {
        let tempClass = classAttr;
        const isActive = href === "/" ? isCurrent : isPartiallyCurrent || isCurrent;
        // const isActive = href === "/" ? isCurrent : isPartiallyCurrent || isCurrent;
        // The object returned here is spread on the anchor element's attributes
        if (isActive) {
            tempClass += " active";
        }

        return { class: tempClass };
    }
</script>

<Link to="{to}" on:click getProps="{getProps}">
    <slot />
</Link>