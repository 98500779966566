import env from "../../env"
import {addAuthHelper, user} from "../store/user"
import {navigate} from "svelte-routing";

export let apiEndpoint = env.apiEndpoint;
export let yummologyApiEndpoint = env.yummologyApiEndpoint;
export let publicStorage = env.publicStorage;

let userValue;

const unsubscribe = user.subscribe((value) => {
  userValue = value;
});

export async function fetchApi(
  endpoint,
  postData,
  method,
  contentType,
  isForm,
  isFile,
) {
  let res;

  const options = {
    method: method || 'GET',
    credentials: 'include', // include, *same-origin, omit
    mode: 'cors', // no-cors, *cors, same-origin
    headers: {
      'Content-Type': contentType || 'application/json',
      Authorization: 'Bearer ' + userValue.token,
      // Authorization: 'Bearer ' + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxLCJ1dWlkIjoiMjZkZjExMGMtMjI2MS00NmM0LTk2ZTgtOGExODdlMDU2NGRlIiwidXNlcm5hbWUiOiIiLCJjb2RlIjoiIiwiZmlyc3RfbmFtZSI6ItmF2LHYqti224wiLCJsYXN0X25hbWUiOiLZhdix2KrYttuMIiwicGhvbmVfbnVtYmVyIjoiOTg5MTk2MzY1NzU1Iiwicm9sZXMiOlsiY29tcGFueV9vd25lciIsImFkbWluIiwiYXBwbGllciIsImFwcHJvdmVyIiwiZGVwb3QiLCJxYyIsInByb2N1cmVtZW50Il0sInBlcm1pc3Npb25zIjpbInByb2R1Y3RfZWRpdCIsImFkZF9kZW1hbmQiLCJlZGl0X2RlbWFuZCIsImFwcGxpZXJfYXBwcm92ZV9kZW1hbmQiLCJhcHByb3Zlcl9hcHByb3ZlX2RlbWFuZCIsImRlcG90X2FwcHJvdmVfZGVtYW5kIiwicHJvY3VyZW1lbnRfYXBwcm92ZV9kZW1hbmQiLCJxY19hcHByb3ZlX2RlbWFuZCIsImFwcHJvdmVyX3JlamVjdF9kZW1hbmQiLCJkZXBvdF9yZWplY3RfZGVtYW5kIiwicHJvY3VyZW1lbnRfcmVqZWN0X2RlbWFuZCIsInFjX3JlamVjdF9kZW1hbmQiLCJwYXlfaW5zcGVjdGlvbl9kZW1hbmQiLCJzZXRfbG9jYXRpb24iLCJhcHByb3Zlcl9hcmNoaXZlIiwiYXBwbGllcl9hcmNoaXZlIiwicmVfb3JkZXIiLCJhZGRfdW5pdCIsImVkaXRfcHJpY2UiLCJlZGl0X3N0b2NrIiwiYWRkX3N0b2NrIiwidmlld19zdG9jayIsImFkZF9sb2NhdGlvbiIsImVkaXRfbG9jYXRpb24iLCJhZGRfYnV5ZXIiLCJ2aWV3X2RlcG90IiwiZWRpdF9kZXBvdCIsInZpZXdfc3RvY2siLCJlZGl0X3N0b2NrIiwidmlld19vYmplY3QiLCJhZGRfY2F0ZWdvcnkiLCJlZGl0X2NhdGVnb3J5Il0sInRva2VuIjoiIiwiaXNfdmVyaWZpZWQiOnRydWUsImNvbXBhbnlfaWQiOjIsImhvbGRpbmdfaWQiOjEsImF2YXRhcl9pZCI6MCwicHJvZmlsZSI6eyJuYXRpb25hbF9jb2RlIjoiMjIyMTEiLCJpZF9jYXJkIjoiNDg0MyIsImVtYWlsIjoic2lhbGFuLnRlYW1AZ21haWwuY29tIiwid2ViX3NpdGVfYWRkcmVzcyI6ImluZm9AZG9tYWluLmNvbSIsInRlbCI6IjAyMTQzNTQzNSJ9LCJzdGF0dXMiOiJhY3RpdmUiLCJsYXN0X2xvZ2luX3RpbWUiOiIyMDIwLTA3LTIzVDAzOjI5OjIyLjI3NjMzMzI1OC0wNzowMCIsInJlc2V0X3Bhc3N3b3JkIjpmYWxzZX0sImV4cCI6MTU5NTY3Mjk2MiwiaXNzIjoidGVzdCJ9.m0YaeGc5As4eD7aT9_lOZbU7ial8J36_5hd-0G6ctgk",
    },
  };

  if (isFile) {
    delete options.headers['Content-Type'];
  }

  if (postData) {
    if (!isFile) {
      options.body = JSON.stringify(postData);
    } else {
      options.body = postData;
    }
  }

  try {
    res = await fetch(endpoint, options);
    const data = await res.json();
    if (res.status === 401) {
      console.log('status:::', res.status);
      let userModel = {token: ""};
      addAuthHelper(userModel);
      user.set(userModel);
      navigate("/login");
    }
    return data;
  } catch (e) {
    return {
      status: false,
      message: "Connection problem!",
    }
  }

}
