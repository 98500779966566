<script>
	import {slide} from 'svelte/transition';
	import {quintOut} from "svelte/easing";
	import {copyObject} from "../../lib/obj";
	import {onMount} from "svelte";

	export let errorMessage;
	export let timeOut = 3500;

	let tempMessage = null;
	let interval;

	onMount(() =>{
		interval = setInterval(function() {
			// method to be executed;
		}, 5000);
	});

	// $: {
	// 	tempMessage = errorMessage ? null : copyObject(errorMessage);
	//   setTimeout(() => {
	// 		tempMessage = null;
	//   }, timeOut)
	// }

</script>

<style>
	ul li {
		color: red;
	}
</style>

{#if tempMessage}
	<ul class="form-error" transition:slide="{{duration: 400, easing: quintOut}}">
		{#if Array.isArray(tempMessage)}
			{#each tempMessage as message}
				<li>{Object.keys(message)[0]}: {Object.values(message)[0]}</li>
			{/each}
		{:else if typeof tempMessage === 'object'}
			<li>{Object.keys(tempMessage)[0]}: {Object.values(tempMessage)[0]}</li>
		{:else if typeof tempMessage === 'string'}
			<li>{tempMessage}</li>
		{/if}
	</ul>
{/if}
