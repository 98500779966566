<script>
    import {user} from "./st"

    $: console.log($user.name);

</script>


<style>
    h1 {
        color: red;
    }
</style>


<h1>{$user.name}</h1>
