export let imageModel = {
	id: 0,
	uuid: "",
	title: "",
};

export let instructionModel = {
	id: 0,
	order: 0,
	name: "",
	text: "",
	image: imageModel,
};

export let ingredientModel = {
	id: 0,
	order: 0,
	ingredient_id: 0,
	linked_recipe_id: 0,
	// ingredient: {
	// 	label: "",
	// 	value: "",
	// },
	amount: 0,
	rename: "",
	measure: "gram",
	measure_rename: "gram",
	type: "",
};

export let tipModel = {
	id: 0,
	order: 0,
	text: "",
};

export let recipeModel = {
	title: "",
	name: "",
	keywords: [],
	category: "",
	description: "",
	images: [imageModel],
	video: {
		uuid: "",
	},
	yield: "",
	prepare_time: 0,
	cook_time: 0,
	total_time: 0,
	serving: 1,
	instructions: [],
	ingredients: [],
	tips: [],
	background: "",
	slug: "",
	meal: [],
	occasion: [],
	cuisine: [],
	season: [],
	diet: [],
	cooking_method: [],
	dish_type: [],
	difficulty: [],
	user_id: 2,
	translator_id: 2,
	status: "",
	created_at: "0001-01-01T00:00:00Z",
	updated_at: "0001-01-01T00:00:00Z",
	published_at: "0001-01-01T00:00:00Z",
	deleted_at: "0001-01-01T00:00:00Z",
	is_imported: false,
	isEditing: false,
};
