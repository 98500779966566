<script>
  import {fade} from 'svelte/transition';
  import {onMount} from "svelte";
  import Spinner from "svelte-spinner"
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import {navigate} from "svelte-routing"
  import {_} from '../../../store/i18n';

  let loading, errorMessage;
  let ingredients = [];
  let query = "";
  let timeout = null;

  $: if(query !== "") {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(function() {
      searchIngredient();
    }, 800);
  } else {
      loadIngredients();
  }

  function searchIngredient() {
    loading = true;
    fetchApi(apiEndpoint + "/admin/ingredient/search", {query: query}, "POST")
    .then(res => {
      if (res.status) {
        ingredients = res.data;
      } else {
        errorMessage = res.message;
      }
    })
    .catch(err => {
    })
    .finally(() => {
      loading = false;
    });
  }

  // onMount(() => {
  //   loadIngredients();
  // });

  function loadIngredients() {
    loading = true;
    fetchApi(apiEndpoint + "/admin/ingredient/get/list", {}, "POST")
    .then(res => {
      if (res.status) {
        ingredients = res.data;
        console.log("ingredients", ingredients);
      } else {
        errorMessage = res.message;
      }
    })
    .catch(err => {
    })
    .finally(() => {
        loading = false;
    });
  }
</script>

<div class="content" in:fade>
  <div class="card plr">
    <h1>{$_.t("ingredient:Ingredient List")}</h1>
  </div>
  <div class="card">
    <input bind:value={query} placeholder="{$_.t('ingredient:type ingredient name')}">
    {#if loading}
      <Spinner />
    {:else}
      {#if ingredients}
        <div class="item-list">
          {#each ingredients as ingredient, i (ingredient.id)}
            <div class="item" on:click={() => navigate("/ingredient/view/"+ingredient.id)}>{ingredient.name} - {ingredient.rename}</div>
          {/each}
        </div>
      {:else}
        <p>{$_.t("ingredient:No ingredients found!")}</p>
      {/if}
    {/if}
    <ErrorMessage errorMessage={errorMessage} />
  </div>
</div>