<script>
    import {user} from "./st"
    import T2 from "./Testing2.svelte"
    import {onMount} from "svelte";

    let mot;

    // $: console.log(mot);

    onMount(() => {
        mot = "hasan";
    });

</script>


<div class="content">

    <h1>akdjkdfhdjs</h1>

    <input type="text" bind:value={mot} placeholder="mot">

    <input type="text" bind:value={$user.name}>

    <T2 />
</div>


