<script>
	import {Router, Route, navigate} from "svelte-routing";
	import Dashboard from "./component/dash/Index.svelte";
	import Login from "./component/auth/Login.svelte";
	import Logout from "./component/auth/Logout.svelte";
	import RecipeList from "./component/recipe/list/Index.svelte";
	import RecipeSearch from "./component/recipe/list/Search.svelte";
	import RecipeListDeleted from "./component/recipe/list/Trash.svelte";
	import RecipeAdd from "./component/recipe/single/Index.svelte";
	import IngredientList from "./component/ingredient/list/Index.svelte";
	import IngredientSingle from "./component/ingredient/single/Index.svelte";
	import IngredientAdd from "./component/ingredient/add/Index.svelte";
	import ImageList from "./component/image/list/Index.svelte";
	import ImageListDeleted from "./component/image/list/Trash.svelte";
	import ImageSingle from "./component/image/single/Index.svelte";
	import ImageAdd from "./component/image/add/Index.svelte";
	import PageList from "./component/page/list/Index.svelte";
	import PageListDeleted from "./component/page/list/Trash.svelte";
	import PageAdd from "./component/page/single/Index.svelte";
	import NavLink from "./component/ui/NavLink.svelte";
	import Testing from "./Testing.svelte"
	import i18next from 'i18next';
	import {_, _Loaded} from './store/i18n';
	import {user} from "./store/user"
	import fa from "../public/lang/fa.json";
	import env from "../env.json";
	import {beforeUpdate, onMount} from "svelte";
	import { SvelteToast } from '@zerodevx/svelte-toast'

	export let url = "";
	// i18next.init({ lng: 'fa', debug: true, resources: {} });
	// // i18next.addResources(env.language, 'translation', fa);
	// i18next.addResourceBundle(env.language, 'translation', fa, true);

	// onMount(() => {
	// 	if ($user.token === "") {
	// 		navigate("/login")
	// 	}
	// });

	// $: redirectIfNotLogin();

	beforeUpdate(() => {
		redirectIfNotLogin();
	});

	function redirectIfNotLogin() {
		if ($user.token === "") {
			navigate("/login");
			return false;
		}
	}

	i18next.init({
		lng: 'fa',
		debug: true,
		resources: {
			fa: fa,
		}
	}, function(err, t) {
		i18next.changeLanguage(env.language);
		_.set(i18next);
		_Loaded.set(true);
		document.body.classList.add(env.dir);
	});

	document.title = env.name;

	let menuText = $_.t("menu:Menu");
	let hideSidebar = true;

	function menuClicked() {
		hideSidebar = true;
		document.getElementsByClassName("main-wrapper")[0].scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	}

	function toggleSidebar() {
		hideSidebar = !hideSidebar;
	}

	console.log($user.isLoggedIn());

</script>

<style>
	:root {
		--toastContainerTop: auto;
		--toastContainerRight: auto;
		--toastContainerBottom: 8rem;
		--toastContainerLeft: calc(50vw - 8rem);
	}
</style>

{#if $_Loaded}
<Router url="{url}"}>
	<div class="main-wrapper">

		{#if $user.isLoggedIn()}
			<div class="menu" on:click={toggleSidebar}>
				{menuText}
			</div>
			<div class="sidebar" class:hide={hideSidebar} on:click={menuClicked}>
				<div class="menu">
					<h3>{$_.t("menu:Recipes")}</h3>
					<NavLink to="recipe/list/page/1">{$_.t("menu:List")}</NavLink>
					<NavLink to="recipe/search/page/1">{$_.t("menu:Search")}</NavLink>
					{#if $user.is('admin') || $user.is('author')}
						<NavLink to="recipe/add">{$_.t("menu:Add")}</NavLink>
					{/if}
					{#if $user.is('admin')}
						<NavLink to="recipe/trash">{$_.t("menu:Trash")}</NavLink>
					{/if}
					<h3>{$_.t("menu:Images")}</h3>
					<NavLink to="image/list/page/1">{$_.t("menu:List")}</NavLink>
					{#if $user.is('admin') || $user.is('author')}
						<NavLink to="image/add">{$_.t("menu:Add")}</NavLink>
					{/if}
					{#if $user.is('admin')}
						<NavLink to="image/trash">{$_.t("menu:Trash")}</NavLink>
					{/if}
					{#if $user.is('admin')}
						<h3>{$_.t("menu:Ingredients")}</h3>
						<NavLink to="ingredient/list">{$_.t("menu:List")}</NavLink>
						<NavLink to="ingredient/add">{$_.t("menu:Add")}</NavLink>
						<h3>{$_.t("menu:Pages")}</h3>
						<NavLink to="page/list">{$_.t("menu:List")}</NavLink>
						<NavLink to="page/add">{$_.t("menu:Add")}</NavLink>
						<NavLink to="page/trash">{$_.t("menu:Trash")}</NavLink>
					{/if}
					<h3>{$_.t("menu:Profile")}</h3>
					<NavLink to="logout">{$_.t("menu:Logout")}</NavLink>
				</div>
			</div>
		{/if}

		<Route path="/login" component={Login} />
		<Route path="/logout" component={Logout} />
		<Route path="/" component={Dashboard} />

		<Route path="recipe/list/page/:pageNumber" component={RecipeList} />
		<Route path="recipe/search/page/:pageNumber" component={RecipeSearch} />
		<Route path="recipe/add" component={RecipeAdd} />
		<Route path="recipe/list/edit/:id" component={RecipeAdd} />
		<Route path="recipe/trash" component={RecipeListDeleted} />
		<Route path="recipe/trash/edit/:id" component={RecipeAdd} />

		<Route path="image/list/page/:pageNumber" component={ImageList} />
		<Route path="image/trash" component={ImageListDeleted} />
		<Route path="image/add" component={ImageAdd} />
		<Route path="image/view/:id" component={ImageSingle} />

		<Route path="ingredient/list" component={IngredientList} />
		<Route path="ingredient/add" component={IngredientAdd} />
		<Route path="ingredient/view/:id" component={IngredientSingle} />

		<Route path="page/list" component={PageList} />
		<Route path="page/add" component={PageAdd} />
		<Route path="page/list/edit/:id" component={PageAdd} />
		<Route path="page/trash" component={PageListDeleted} />
		<Route path="page/trash/edit/:id" component={PageAdd} />

		<Route path="test" component={Testing} />

	</div>
</Router>
{/if}
<SvelteToast options={{ reversed: true, intro: { y: -10 } }}/>

