<script>
  import {fade} from 'svelte/transition';
  import {onMount} from "svelte";
  import Spinner from "svelte-spinner"
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import {navigate} from "svelte-routing"
  import PublicImage from "../../ui/PublicImage.svelte";
  import {publicStorage} from "../../../lib/api";
  import {_} from '../../../store/i18n';
  import {user} from "../../../store/user";

  export let id;

  let loading, errorMessage;
  let image;

  onMount(() => {
    loadImage();
  });

  function loadImage() {
    loading = true;
    fetchApi(apiEndpoint + "/admin/image/get/single", {id: parseInt(id)}, "POST")
    .then(res => {
      if (res.status) {
        image = res.data;
        console.log("image", image);
      } else {
        errorMessage = res.message;
      }
    })
    .catch(err => {
    })
    .finally(() => {
        loading = false;
    });
  }

  function changeStatus(action) {
    loading = true;
    fetchApi(apiEndpoint + "/admin/image/"+action, {id: image.id}, "POST")
    .then(res => {
      if (res.status) {
        if (action === "delete") {
          navigate("/image/list/page/1")
        } else {
          loadImage();
        }
      } else {
        errorMessage = res.message;
      }
    })
    .finally(() => {
        loading = false;
    });
  }

  function saveImage() {
    loading = true;
    fetchApi(apiEndpoint + "/admin/image/edit", image, "PUT")
    .then(res => {
      if (res.status) {
        loadImage();
      } else {
        errorMessage = res.message;
      }
    })
    .finally(() => {
        loading = false;
    });
  }
</script>

<div class="content" id="page-image-single" in:fade>
  <div class="card">
    {#if loading}
      <Spinner />
    {:else if image}
      <h1>{image.title}</h1>
      <div class="horizontal flex-same">
        <div class="photo">
          <PublicImage uuid={image.uuid} size="m" />
          {#if $user.is('admin')}
            <a target="_blank" href="{publicStorage}/image/{image.uuid.substring(0,2)}/{image.uuid.substring(2,4)}/{image.uuid}-xl.jpg">
              {$_.t("recipe:xLarge")}
            </a>
            <a target="_blank" href="{publicStorage}_bk/image/{image.uuid.substring(0,2)}/{image.uuid.substring(2,4)}/{image.uuid}{image.extension}">
              {$_.t("recipe:Backup")}
            </a>
          {/if}
        </div>
        <div>
          <div class="url-preview">{publicStorage}/image/{image.uuid.substring(0,2)}/{image.uuid.substring(2,4)}/{image.uuid}-s.jpg</div>
          <label>{$_.t("image:Description")}</label>
          <textarea bind:value={image.title} rows="8"></textarea>
          <div class="actions">
            <button class="light-green bold pointer" on:click={saveImage} disabled={loading}>{$_.t("image:Save")}</button>
            {#if $user.is('admin')}
              {#if image.status !== "deleted"}
                <button class="red bold pointer" on:click={() => changeStatus("soft-delete")} disabled={loading}>{$_.t("image:Trash")}</button>
              {:else}
                <button class="green bold pointer" on:click={() => changeStatus("recover")} disabled={loading}>{$_.t("image:Recover")}</button>
                <button class="red bold pointer" on:click={() => changeStatus("delete")} disabled={loading}>{$_.t("image:Permanently Delete")}</button>
              {/if}
            {/if}
          </div>
        </div>
      </div>
      <ErrorMessage errorMessage={errorMessage} />
    {/if}
  </div>
</div>