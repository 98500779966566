<script>
	import {fade} from "svelte/transition"
	import Spinner from 'svelte-spinner';
	import slugify from 'slugify';
	import {navigate} from "svelte-routing";
	// import moment from "moment";
	import ErrorMessage from "../../../ui/ErrorMessage.svelte"
	import {recipe} from "../../../../store/recipe/add";
	import {apiEndpoint, fetchApi, yummologyApiEndpoint} from "../../../../lib/api";
	import {copyObject} from "../../../../lib/obj";
	import {createEventDispatcher} from "svelte";
	import {_} from '../../../../store/i18n';
	import {user} from "../../../../store/user";
	import env from "../../../../../env"
	// import Modal from "../../../ui/Modal.svelte";
	// import PublicImage from "../../../ui/PublicImage.svelte";
	import { toast } from '@zerodevx/svelte-toast'

	let loading, errorMessage;
	// let isDraftOpen = false;
	let nullDate = "0001-01-01T00:00:00Z";
	export let status = "draft";

	const dispatch = createEventDispatcher();

	// function openDraftModal() {
	// 	isDraftOpen = true;
	// }
	//
	// function closeModal() {
	// 	isDraftOpen = false;
	// }

	function changeStatus(action) {
		loading = true;
		fetchApi(apiEndpoint + "/admin/recipe/" + action, {id: $recipe.id, uuid: $recipe.uuid}, "POST")
						.then(res => {
							if (res.status) {
								if (action === "delete") {
									navigate("/recipe/list/page/1")
								} else {
									dispatch("reload", {id: res.data.id});
								}
							} else {
								errorMessage = res.message;
							}
						})
						.finally(() => {
							loading = false;
						});
	}

	function saveRecipe(isImport) {
		loading = true;
		let tempRecipe = copyObject($recipe);
		tempRecipe.keywords = [];
		["meal",
			"occasion",
			"cuisine",
			"season",
			"diet",
			"cooking_method",
			"dish_type",
			"difficulty",
			"course"
		].forEach((key) => {
			console.log("tempRecipe[key]", key, tempRecipe[key]);
			if (tempRecipe[key] && tempRecipe[key].length > 0) {
				tempRecipe.keywords.push(...tempRecipe[key]);
			}
		});
		if (tempRecipe.instructions) {
			tempRecipe.instructions.map((instruction, i) => {
				instruction.order = i + 1;
				if (instruction.image) {
					instruction.image_id = instruction.image.id
				}
			});
		}
		if (tempRecipe.ingredients) {
			tempRecipe.ingredients.map((ingredient, i) => {
				ingredient.order = i + 1;
			});
		}
		if (tempRecipe.tips) {
			tempRecipe.tips.map((tip, i) => {
				tip.order = i + 1;
			});
		}
		tempRecipe.status = status;
		let method = "POST";
		let url = apiEndpoint + "/admin/recipe/add";
		if ($recipe.isEditing) {
			method = "PUT";
			url = apiEndpoint + "/admin/recipe/edit"
		}
		if(isImport) {
			$recipe.is_imported = true;
			tempRecipe.is_imported = true;
			method = "POST";
			url = yummologyApiEndpoint + "/admin/recipe/import";
		}
		if($user.is('translator') && tempRecipe.status == "translate_review") {
			tempRecipe.translate = JSON.stringify(tempRecipe);
			tempRecipe.translate_review = "";
		} else if($user.is('translate_editor') && tempRecipe.status == "pending") {
			tempRecipe.translate = "";
			tempRecipe.translate_review = JSON.stringify(tempRecipe);
		} else {
			tempRecipe.translate = "";
			tempRecipe.translate_review = "";
		}
		tempRecipe.slug = slugify(tempRecipe.slug.toLowerCase());
		fetchApi(url, tempRecipe, method)
			.then(res => {
				if (res.status) {
					if(isImport) {
						console.log("import to yummology :::: res.data", res.data);
					} else if($recipe.isEditing) {
						console.log("saveRecipe :::: res.data", res.data);
						recipe.set(res.data);
						dispatch("reload", {id: res.data.id});
					} else {
						navigate("/recipe/list/edit/" + res.data.id);
					}
				} else {
					toast.push(res.message, {
						theme: {
							'--toastBackground': '#F56565',
							'--toastProgressBackground': '#C53030'
						}
					});
					errorMessage = res.message;
					console.log("saveRecipe error :::: res.data", res);
				}
			})
			.finally(() => {
				loading = false;
			});
	}

	// function replaceDraft(draftRecipe) {
	// 	console.log("replaceDraft!!!!")
	// 	draftRecipe.uuid = $recipe.uuid;
	// 	recipe.set(draftRecipe);
	// 	closeModal();
	// }

	// function getDate(d, format) {
	// 	if (d === nullDate) {
	// 		return "-";
	// 	}
	// 	return moment(d).format(format);
	// }
</script>

<div class="actions" in:fade>
	<br>
	{#if loading}
		<Spinner />
	{/if}
	<ErrorMessage errorMessage={errorMessage}/>
	<label>{$_.t("recipe:Status")}
		<select bind:value={status}>
			{#if $user.is('admin') || $user.is('author') || $user.is('recipe_editor')}
				<option value="draft">{$_.t("recipe-list:draft")}</option>
				<option value="recipe_review">{$_.t("recipe-list:recipe_review")}</option>
			{/if}
			{#if $user.is('admin') || $user.is('translator') || $user.is('recipe_editor') || $user.is('translate_editor')}
				<option value="translate">{$_.t("recipe-list:translate")}</option>
				<option value="translate_review">{$_.t("recipe-list:translate_review")}</option>
			{/if}
			{#if $user.is('admin') || $user.is('translate_editor')}
				<option value="pending">{$_.t("recipe-list:pending")}</option>
			{/if}
			{#if $user.is('admin')}
				<option value="published">{$_.t("recipe-list:publish")}</option>
<!--				<option value="deleted">{$_.t("recipe-list:trash")}</option>-->
			{/if}
		</select>
	</label>
	{#if $user.is('admin')}
		<p>
			<input type=checkbox bind:checked={$recipe.is_imported}> {$_.t("recipe:Imported")}
		</p>
	{/if}
	{#if $user.is('admin') || $user.is('translate_editor')}
		<label for="translator_rating">{$_.t("recipe:Translator rating")}
			<input id="translator_rating" type="number" min="1" max="5" bind:value={$recipe.translator_rating}>
		</label>
	{/if}
	<button class="light-green bold pointer" on:click={() => saveRecipe(false)} disabled={loading}>{$_.t("recipe:Save")}</button>
	{#if $recipe.status !== "" && $user.is('admin')}
		{#if $recipe.status !== "deleted"}
			<button class="red bold pointer" on:click={() => changeStatus("soft-delete")} disabled={loading}>{$_.t("recipe:Trash")}</button>
		{:else}
			<button class="green bold pointer" on:click={() => changeStatus("recover")} disabled={loading}>{$_.t("recipe:Recover")}</button>
			<button class="red bold pointer" on:click={() => changeStatus("delete")} disabled={loading}>{$_.t("recipe:Permanently Delete")}</button>
		{/if}
		<button class="orange bold pointer" on:click={() => dispatch("reload", {"id": $recipe.id})} disabled={loading}>{$_.t("recipe:Reset")}</button>
		{#if env.language === "fa"}
			<button class="blue bold pointer" on:click={() => saveRecipe(true)} disabled={loading}>{$_.t("recipe:Yummology Import")}</button>
		{/if}
	{/if}
	{#if $recipe.status === "published" && $recipe.user && $recipe.user.user_name}
		<a href="{env.baseUrl}/{$recipe.user.user_name}/r/{$recipe.uuid}" target="_blank">{$_.t("recipe:View")}</a>
	{/if}
<!--	<span class="pointer" on:click={openDraftModal}>{$_.t("recipe:Drafts")}</span>-->
</div>
<!--<Modal isOpen={isDraftOpen} on:closed={closeModal}>-->
<!--	<div slot="main" class="content">-->
<!--		<div class="card drafts">-->
<!--			<div class="item-list">-->
<!--				{#if $recipeDrafts[$recipe.uuid]}-->
<!--					{#if $recipeDrafts[$recipe.uuid].length > 0}-->
<!--						<div class="item-list">-->
<!--							<div class="item heading">-->
<!--								<div class="thumb">{$_.t("recipe-list:Ph")}</div>-->
<!--								<div class="title">{$_.t("recipe-list:Title")}</div>-->
<!--								<div>{$_.t("recipe-list:ّIngredients")}</div>-->
<!--								<div>{$_.t("recipe-list:Instructions")}</div>-->
<!--								<div>{$_.t("recipe-list:Tips")}</div>-->
<!--								<div>{$_.t("recipe-list:Saved")}</div>-->
<!--							</div>-->
<!--							{#each $recipeDrafts[$recipe.uuid] as draft}-->
<!--								{#if draft}-->
<!--								<div class="item" on:click={() => replaceDraft(draft.recipe)}>-->
<!--									<div class="thumb">-->
<!--										{#if draft.recipe.images && draft.recipe.images[0]}-->
<!--											<PublicImage uuid={draft.recipe.images[0].uuid} size="1x1-m" />-->
<!--										{/if}-->
<!--									</div>-->
<!--									<div class="title">-->
<!--										<div class="bold">{draft.recipe.title}</div>-->
<!--&lt;!&ndash;										<small class="gray">{draft.recipe.user.user_name}</small>&ndash;&gt;-->
<!--									</div>-->
<!--									<div>{draft.recipe.ingredients ? draft.recipe.ingredients.length : 0}</div>-->
<!--									<div>{draft.recipe.instructions? draft.recipe.instructions.length : 0}</div>-->
<!--									<div>{draft.recipe.tips ? draft.recipe.tips.length : 0}</div>-->
<!--									<div>-->
<!--										<small class="gray">{getDate(draft.date, "D,M,YYYY")}</small><br>-->
<!--										{getDate(draft.date, "hh:mm:ss")}-->
<!--									</div>-->
<!--								</div>-->
<!--								{/if}-->
<!--							{/each}-->
<!--						</div>-->
<!--					{:else}-->
<!--						<p>No recipe yet!</p>-->
<!--					{/if}-->
<!--				{/if}-->
<!--			</div>-->
<!--		</div>-->
<!--	</div>-->
<!--</Modal>-->