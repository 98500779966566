import {writable} from "svelte/store";

let is = function(role) {
  if (!this.hasOwnProperty('roles')) {
    return false;
  }
  if (this.roles == null) {
    return false;
  }
  return this.roles.includes(role);
};

let isLoggedIn = function() {
  if (!this.hasOwnProperty('token')) {
    return false;
  }
  if (this.token === null || this.token === "") {
    return false;
  }
  return true;
};

let userLocalStorage = JSON.parse(localStorage.getItem("user"));
let tempUser = (userLocalStorage) ? userLocalStorage : {token: ""};
tempUser = addAuthHelper(tempUser);

export const user = writable(tempUser);
user.subscribe(val => {
  localStorage.setItem("user", JSON.stringify(val));
});

export function addAuthHelper(obj) {
  obj["is"] = is;
  obj["isLoggedIn"] = isLoggedIn;
  return obj;
}
