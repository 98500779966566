<script>
  import {fade} from 'svelte/transition';
  import Spinner from 'svelte-spinner';
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import {imageModel, recipeModel} from "./model"
  import {onDestroy, onMount} from "svelte";
  import {copyObject} from "../../../lib/obj";
  import {recipe} from "../../../store/recipe/add";
  import Photo from "./parts/Photo.svelte";
  import Primary from "./parts/Primary.svelte";
  import Meta from "./parts/Meta.svelte";
  import Instructions from "./parts/Instructions.svelte";
  import Ingredients from "./parts/Ingredients.svelte";
  import Tips from "./parts/Tips.svelte";
  import Modal from "../../ui/Modal.svelte";
  import PublicImage from "../../ui/PublicImage.svelte";
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import Cook from "./parts/Cook.svelte";
  import ImageList from "../../image/list/Index.svelte"
  import {_} from '../../../store/i18n';
  import {user} from "../../../store/user";
  import {navigate} from "svelte-routing";

  let loading,
      loadingRecipe,
      errorMessage;
  let isUploadOpen = false;
  let targetObject;
  let targetField;
  let targetIndex;
  let uploadFiles = [];
  let uploadTitle = "";
  let previewImage;
  let showForm = false;
  // let draftInterval;

  export let id;
  recipeModel.user_id = $user.id;
  recipe.set(copyObject(recipeModel));

  onMount(() => {
    if (id && id !== "") {
      loadRecipe();
    }
    // draftInterval = setInterval(saveDraft, 5000);
  });

  onDestroy(() => {
    // clearInterval(draftInterval);
    recipe.set(copyObject(recipeModel));
  });

  // function saveDraft() {
  //   let now = new Date();
  //   if (loadingRecipe) {
  //     return;
  //   }
  //   if ($recipe.uuid === "") {
  //     return;
  //   }
  //   if (!Array.isArray($recipeDrafts[$recipe.uuid])) {
  //     console.log("not array!");
  //     console.log("1----", $recipe.uuid)
  //     console.log("2----", $recipeDrafts[$recipe.uuid])
  //     $recipeDrafts[$recipe.uuid] = [{date: now, recipe: copyObject($recipe)}];
  //     recipeDrafts.set($recipeDrafts);
  //     return;
  //   }
  //   if (JSON.stringify($recipeDrafts[$recipe.uuid][0].recipe) === JSON.stringify($recipe)) {
  //     return;
  //   }
  //   $recipeDrafts[$recipe.uuid].unshift({date: now, recipe: copyObject($recipe)});
  //   $recipeDrafts[$recipe.uuid].length = 20;
  //   let newObj = {};
  //   Object.entries($recipeDrafts).map((item, i) => {
  //     if (i < 3) {newObj[item[0]] = item[1]}
  //     console.log(i, item)
  //   });
  //   recipeDrafts.set(newObj);
  // }

  function openModal(event) {
    resetModal();
    isUploadOpen = true;
    //TODO check if is array push
    targetObject = event.detail.object;
    targetField = event.detail.field;
    targetIndex = event.detail.index;

    if (!targetObject[targetField][targetIndex]) {
      targetObject[targetField][targetIndex] = copyObject(imageModel);
    }

    console.log("targetIndex", targetIndex);
  }

  function resetModal() {
    errorMessage = "";
    previewImage = null;
    uploadTitle = "";
    uploadFiles = [];
  }

  function fileChange() {
    console.log("file changed!");
    let reader = new FileReader();
    reader.onload = function (e) {
      previewImage = e.target.result;
    };
    reader.readAsDataURL(uploadFiles[0]);
  }

  function closeModal() {
    isUploadOpen = false;
    showForm = false;
  }

  function reload(e) {
    id = e.detail.id;
    // saveDraft();
    loadRecipe();
  }

  function loadRecipe() {
    console.log("loading recipe!");
    loadingRecipe = true;
    fetchApi(apiEndpoint + "/admin/recipe/get/single", {id: parseInt(id)}, "POST")
    .then(res => {
      if (res.status) {
        if (
            $user.is("admin")
            || ($user.is("author") && res.data.status === "draft" && $user.id === res.data.user_id)
            || ($user.is("author") && res.data.status === "recipe_review" && $user.id === res.data.user_id)
            || ($user.is("translator") && res.data.status === "translate" && $user.id === res.data.translator_id)
            || ($user.is("recipe_editor") && res.data.status === "recipe_review")
            || ($user.is("translate_editor") && res.data.status === "translate_review" && $user.id === res.data.translate_editor_id)
        ) {
        } else {
          navigate("/");
        }
        if(res.data.original != null && res.data.original !== "") {
          res.data.original = JSON.parse(res.data.original);
        }
        if(res.data.translate_review != null && res.data.translate_review !== "") {
          res.data.translate_review = JSON.parse(res.data.translate_review);
        }
        if(res.data.translate != null && res.data.translate !== "") {
          res.data.translate = JSON.parse(res.data.translate);
        }
        [
          "meal",
          "occasion",
          "cuisine",
          "season",
          "diet",
          "cooking_method",
          "dish_type",
          "difficulty",
          "course"
        ].forEach((key) => {
          if (!res.data[key]) {
            res.data[key] = [];
          }
        });
        if (res.data.keywords) {
          res.data.keywords.map((keyword) => {
            console.log("keyword", keyword);
            res.data[keyword.type].push({
              label: keyword.title,
              value: keyword.id,
              id: keyword.id,
            });
          });
        }
        if (!res.data.images) {
          res.data.images = [];
        }
        if (res.data.ingredients && res.data.ingredients.length > 0) {
          res.data.ingredients.map((ingredient, index) => {
            console.log("ingredient", ingredient);
            if(ingredient.type === "recipe" && ingredient.linked_recipe) {
              ingredient.linked_recipe.label = ingredient.linked_recipe.title;
              ingredient.linked_recipe.value = ingredient.id;
              if (!ingredient.linked_recipe.measure || ingredient.linked_recipe.measure === "") {
                ingredient.linked_recipe.measure = "gram"
              }
            } else if (ingredient.type === "ingredient") {
              ingredient.ingredient.label = ingredient.ingredient.name;
              ingredient.ingredient.value = ingredient.id;
              if (!ingredient.ingredient.measure || ingredient.ingredient.measure === "") {
                ingredient.ingredient.measure = "gram"
              }
            }
          });
        }
        res.data.isEditing = true;
        recipe.set(res.data);
      } else {
      }
    })
    .finally(() => {
      loadingRecipe = false;
    });
  }

  function select(image) {
    if (targetIndex != null) {
      targetObject[targetField][targetIndex] = image;
    } else {
      targetObject[targetField] = image;
    }
    recipe.set($recipe);
    console.log("$recipe after select ===>", $recipe);
    closeModal();
  }

  function upload() {
    loading = true;
    const formData = new FormData();
    formData.append('title', uploadTitle);
    formData.append('file', uploadFiles[0]);
    fetchApi(apiEndpoint + "/admin/image/add", formData, "POST", null, null, true)
    .then(res => {
      if (res.status === true) {
        if (targetIndex != null) {
          targetObject[targetField][targetIndex] = res.data;
        } else {
          targetObject[targetField] = res.data;
        }
        recipe.set($recipe);
        console.log("$recipe after upload ===>", $recipe);
        // targetObject = null;
        // targetField = null;
        // targetIndex = null;
        closeModal();
      } else {
        errorMessage = res.message;
      }
    })
    .finally(() => {
      loading = false;
    });
  }
</script>

<div class="content" id="page-recipe-add" in:fade>
  <div class="card plr">
    {#if id && id !== ""}
      <h1>{$_.t("recipe:Edit Recipe")}</h1>
    {:else}
      <h1>{$_.t("recipe:Add New Recipe")}</h1>
    {/if}
  </div>
  {#if loadingRecipe}
    <Spinner />
  {:else if $recipe}
    <div class="intro-section horizontal flex-same">
      <Photo on:upload={openModal} on:reload={reload}/>
      <Primary />
      <Meta />
      <Cook />
    </div>
    <Ingredients />
    <Instructions on:upload={openModal}/>
    <Tips />
  {/if}
  <Modal isOpen={isUploadOpen} on:closed={closeModal}>
    <div slot="main" class="content upload">
      {#if showForm}
        <div class="horizontal flex-same" in:fade>
          <div>
            {#if previewImage}
              <img width="200" height="200" src={previewImage} alt="">
            {:else}
              <PublicImage uuid="" classAttr="preview"/>
            {/if}
          </div>
          <div class="upload-form">
            <label>Select image file
              <input type="file" bind:files={uploadFiles} on:change={() => fileChange()} disabled={loading}>
            </label>
            <label>Description
              <textarea bind:value={uploadTitle} rows="6" disabled={loading}></textarea>
            </label>
            <button class="pointer" on:click={() => upload()} disabled="{uploadFiles.length === 0 || loading}">Upload</button>
          </div>
        </div>
        <br>
      {:else}
        <button class="green full-width pointer" on:click={() => showForm = true}>Upload New</button>
      {/if}
      {#if loading}
        <Spinner />
      {/if}
      <ErrorMessage errorMessage={errorMessage} />
      <ImageList inModal={true} on:select={(e) => select(e.detail)} />
    </div>
  </Modal>
</div>