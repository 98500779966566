<script>
	import {recipe} from "../../../../store/recipe/add";
  import {_} from '../../../../store/i18n';
  import {user} from "../../../../store/user";
  import {apiEndpoint, fetchApi} from "../../../../lib/api";
  import {onMount} from "svelte";
  import { toast } from '@zerodevx/svelte-toast'

  let loading, errorMessage;
  let users = [];

  onMount(() => {
    getUsers();
  });

  function getUsers() {
    loading = true;
    fetchApi(apiEndpoint + "/admin/user/get/list", {
      status: ["active"],
      page: 1,
      per_page: 20,
    }, "POST")
      .then(res => {
        if (res.status) {
          users = res.data;
        } else {
          errorMessage = res.message;
          toast.push(res.message, {
            theme: {
              '--toastBackground': '#F56565',
              '--toastProgressBackground': '#C53030'
            }
          })
        }
      })
      .finally(() => {
        loading = false;
      });
  }
</script>

<div class="primary-section card">
  <label>{$_.t("recipe:Title")}
    <input bind:value={$recipe.title}>
    {#if $recipe.original}<div class="translate">{$recipe.original.title}</div>{/if}
    {#if $recipe.translate}<div class="translate">{$recipe.translate.title}</div>{/if}
  </label>
  <label>{$_.t("recipe:Name")}
    <input bind:value={$recipe.name}>
    {#if $recipe.original}<div class="translate">{$recipe.original.name}</div>{/if}
    {#if $recipe.translate}<div class="translate">{$recipe.translate.name}</div>{/if}
  </label>
  {#if $user.is('admin')}
  <label>{$_.t("recipe:SLug")}
    <input bind:value={$recipe.slug}>
    {#if $recipe.original}<div class="translate">{$recipe.original.slug}</div>{/if}
    {#if $recipe.translate}<div class="translate">{$recipe.translate.slug}</div>{/if}
  </label>
  {/if}
  <label>{$_.t("recipe:Chef")}
    <select bind:value={$recipe.user_id} disabled={!$user.is('admin') || loading}>
      <option value="0">{$_.t("recipe-list:-")}</option>
      {#each users as user}
        {#if user.roles.includes("author")}
          <option value={user.id}>{user.first_name} {user.last_name}</option>
        {/if}
      {/each}
    </select>
  </label>
  <label>{$_.t("recipe:Translator")}
    <select bind:value={$recipe.translator_id} disabled={!$user.is('admin') || loading}>
      <option value="0">{$_.t("recipe-list:-")}</option>
      {#each users as user}
        {#if user.roles.includes("translator")}
          <option value={user.id}>{user.first_name} {user.last_name}</option>
        {/if}
      {/each}
    </select>
  </label>
  <label>{$_.t("recipe:Reviewer")}
    <select bind:value={$recipe.translate_editor_id} disabled={!$user.is('admin') || loading}>
      <option value="0">{$_.t("recipe-list:-")}</option>
      {#each users as user}
        {#if user.roles.includes("translate_editor")}
          <option value={user.id}>{user.first_name} {user.last_name}</option>
        {/if}
      {/each}
    </select>
  </label>
  <label>{$_.t("recipe:Description")}
    <textarea bind:value={$recipe.description} rows="8"></textarea>
    {#if $recipe.original}<div class="translate">{$recipe.original.description}</div>{/if}
    {#if $recipe.translate}<div class="translate">{$recipe.translate.description}</div>{/if}
  </label>
  <label>{$_.t("recipe:Background")}
	  <textarea bind:value={$recipe.background} rows="8"></textarea>
    {#if $recipe.original}<div class="translate">{$recipe.original.background}</div>{/if}
    {#if $recipe.translate}<div class="translate">{$recipe.translate.background}</div>{/if}
  </label>
</div>