<script>
  import {fade} from 'svelte/transition';
  import {onMount} from "svelte";
  import moment from "moment";
  import Spinner from "svelte-spinner"
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import {navigate} from "svelte-routing"
  import PublicImage from "../../ui/PublicImage.svelte";
  import {_} from '../../../store/i18n';
  import {user} from "../../../store/user";
  import { toast } from '@zerodevx/svelte-toast'

  let loading, errorMessage;
  let recipes = [];
  let nullDate = "0001-01-01T00:00:00Z";
  let query = "";
  let timeout = null;
  let meta = {
    page: 1,
    per_page: 10,
    total: 0
  };

  $: if(query !== "") {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(function() {
      searchRecipe(1);
    }, 300);
  } else {
    meta = {
      page: 1,
      per_page: 10,
      total: 0
    }
    recipes = [];
  }

  function searchRecipe(page) {
    loading = true;
    meta.page = page;
    fetchApi(apiEndpoint + "/admin/recipe/search", {
      query: query,
      status: [
        "draft",
        "translate",
        "review",
        "pending",
        "published",
        "recipe_review",
        "translate_review",
      ],
      page: meta.page,
      per_page: meta.per_page,
    }, "POST")
    .then(res => {
      if (res.status) {
        recipes = res.data;
        meta.total = res.meta.total;
      } else {
        errorMessage = res.message;
        toast.push(res.message, {
          theme: {
            '--toastBackground': '#F56565',
            '--toastProgressBackground': '#C53030'
          }
        });
      }
    })
    .finally(() => {
      loading = false;
    });
  }

  function canView(recipe) {
    if (
      $user.is("admin")
        || ($user.is("author") && recipe.status === "draft" && $user.id === recipe.user_id)
        || ($user.is("author") && recipe.status === "recipe_review" && $user.id === recipe.user_id)
        || ($user.is("translator") && recipe.status === "translate" && $user.id === recipe.translator_id)
        || ($user.is("recipe_editor") && recipe.status === "recipe_review")
        || ($user.is("translate_editor") && recipe.status === "translate_review")
    ) {
      return true;
    }
    return false;
  }

  function getDate(d) {
    if (d === nullDate) {
      return "-";
    }
    return moment(d).format("D, M, YYYY  hh:mm");
  }
</script>

<div class="content" id="page-recipe-list" in:fade>
  <div class="card plr">
    <h1>{$_.t("recipe-list:Recipe Search")}</h1>
  </div>
  <input class="full-width" bind:value={query} placeholder={$_.t("recipe-list:type recipe title")}>

  <div class="card">
    {#if loading}
      <Spinner />
    {:else}
      {#if recipes.length > 0}
      <div class="item-list">
        <div class="item heading">
          <div class="thumb">{$_.t("recipe-list:Ph")}</div>
          <div class="title">{$_.t("recipe-list:Title")}</div>
          <div>{$_.t("recipe-list:Status")}</div>
          <div>{$_.t("recipe-list:Published")}</div>
        </div>
        {#each recipes as recipe, i (recipe.id)}
          {#if recipe !== null}
            <div class="item"
                 on:click={canView(recipe) ? navigate("/recipe/list/edit/"+recipe.id) : null} class:gray={!canView(recipe)}>
              <div class="thumb">
                {#if recipe.images && recipe.images[0]}
                  <PublicImage uuid={recipe.images[0].uuid} size="1x1-m" />
                {/if}
              </div>
              <div class="title">
                <div class="bold">{recipe.title} {#if $user.is("admin") && recipe.is_imported}&reg;{/if}</div>
                <small class="gray">{recipe.user.user_name}</small>
              </div>
              <div>
                <div class="chips" class:orange={recipe.status === "draft"} class:green={recipe.status === "published"}>{$_.t("recipe-list:"+recipe.status)}</div>
              </div>
              <div>{getDate(recipe.published_at)}</div>
            </div>
          {/if}
        {/each}
      </div>
      {:else}
        <p>No recipe yet!</p>
      {/if}
    {/if}
    <div class="list-pagination">
      {#if meta.page !== 1}
        <div class="chips" on:click={() => navigate("/recipe/search/page/"+(meta.page-1))}>{$_.t("pagination:Prev")}</div>
      {:else}
        <div class="chips inactive">{$_.t("pagination:Prev")}</div>
      {/if}
      {#if meta.total > (meta.page*meta.per_page)}
        <div class="chips" on:click={() => navigate("/recipe/search/page/"+(meta.page+1))}>{$_.t("pagination:Next")}</div>
      {:else}
        <div class="chips inactive">{$_.t("pagination:Next")}</div>
      {/if}
      <div class="gray"><small>{$_.t("pagination:Page")}: {meta.page}</small></div>
    </div>
    <ErrorMessage errorMessage={errorMessage} />
  </div>
</div>