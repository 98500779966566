import { writable } from 'svelte/store';

// export const recipe = writable([]);
export const recipe = writable(JSON.parse(localStorage.getItem("recipe_to_add")) || null);
recipe.subscribe(val => {
  localStorage.setItem("recipe_to_add", JSON.stringify(val));
});

export const recipeDrafts = writable(JSON.parse(localStorage.getItem("recipe_drafts")) || {});
recipeDrafts.subscribe(val => {
  localStorage.setItem("recipe_drafts", JSON.stringify(val));
});
