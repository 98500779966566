<script>
	import {recipe} from "../../../../store/recipe/add";
  import {tipModel} from "../model"
  import {copyObject} from "../../../../lib/obj";
	import {moveInArray} from "../../../../lib/array";
	import {_} from '../../../../store/i18n';
	import {user} from "../../../../store/user";

	function addNew() {
		let item = copyObject(tipModel);
		if (!$recipe.tips) {
			$recipe.tips = []
		}
		$recipe.tips.push(item);
		recipe.set($recipe);
	}

	function remove(index) {
		$recipe.tips = $recipe.tips.filter((item, i) => i !== index);
		recipe.set($recipe);
	}

	function move(arr, old_index, new_index) {
		if (new_index < 0 || new_index > arr.length-1) {
			return false;
		}
		arr = moveInArray(arr, old_index, new_index);
		recipe.set($recipe);
	}

	function canEdit() {
		if (
			$user.is('admin')
			|| $user.is('recipe_editor')
			|| $user.is('author')
		) {
			return true;
		}
		return false;
	}
</script>

<div class="tips-section card">
	<h3 class="bordered">{$_.t("recipe:Tips")}</h3>
	<div class="tips">
		{#if $recipe.tips}
		{#each $recipe.tips as tip,i (i)}
			<div class="tip">
				{#if canEdit()}
					<div class="sort">
						{#if i-1 >= 0}
							<div on:click={() => move($recipe.tips, i, i-1)}>
								{#if $_.dir() === "rtl"}&#8594;{:else}&#8592;{/if}
							</div>
						{:else}
							<div></div>
						{/if}
						<div on:click={() => remove(i)}>X</div>
						{#if i+1 < $recipe.tips.length}
							<div on:click={() => move($recipe.tips, i, i+1)}>
								{#if $_.dir() === "rtl"}&#8592;{:else}&#8594;{/if}
							</div>
						{:else}
							<div></div>
						{/if}
					</div>
				{/if}
				<input type="text" bind:value={$recipe.tips[i].name} placeholder="Name or Title">
				{#if $recipe.original && $recipe.original.tips && $recipe.original.tips[i]}<div class="translate">{$recipe.original.tips[i].name}</div>{/if}
				{#if $recipe.translate && $recipe.translate.tips && $recipe.translate.tips[i]}<div class="translate">{$recipe.translate.tips[i].name}</div>{/if}
				<textarea bind:value={$recipe.tips[i].text} rows="10" placeholder="Text"></textarea>
				{#if $recipe.original && $recipe.original.tips && $recipe.original.tips[i]}<div class="translate">{$recipe.original.tips[i].text}</div>{/if}
				{#if $recipe.translate && $recipe.translate.tips && $recipe.translate.tips[i]}<div class="translate">{$recipe.translate.tips[i].text}</div>{/if}
			</div>
		{/each}
		{/if}
		{#if canEdit()}
			<button class="pointer add" on:click={() => addNew()}>+</button>
		{/if}
	</div>
</div>