<script>
  import {fade} from 'svelte/transition';
  import {onMount} from "svelte";
  import Spinner from "svelte-spinner"
  import Quill from 'quill';
  import {_} from '../../../store/i18n';
  import {navigate} from "svelte-routing"
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import {copyObject} from "../../../lib/obj";
  import env from "../../../../env"

  export let id;
  let loading, errorMessage;
  let status = "draft";
  let method = "POST";
  let urlPart = "add";
  let page = {
    id: 0,
    title: "",
    slug: "",
    description: "",
    content: "",
    status: "",
  };
  let editor;

  onMount(() => {
    if (id && id !== "") {
      page.id = parseInt(id);
      loadPage();
    }
    editor = new Quill('#editor', {
      theme: 'snow'
    });
    editor.on('text-change', function(delta, oldDelta, source) {
      page.content = editor.root.innerHTML;
    })
  });

  function loadPage() {
    loading = true;
    fetchApi(apiEndpoint + "/admin/page/get/single", {
      id: page.id,
    }, "POST")
    .then(res => {
      if (res.status) {
        page = res.data;
        status = res.data.status;
        editor.setContents(editor.clipboard.convert(page.content), 'silent')
      } else {
        errorMessage = res.message;
      }
    })
    .finally(() => {
      loading = false;
    });
  }

  function changeStatus(action) {
    loading = true;
    fetchApi(apiEndpoint + "/admin/page/"+action, {id: page.id}, "POST")
    .then(res => {
      if (res.status) {
        if (action === "delete") {
          navigate("/page/list")
        } else {
          loadPage();
        }
      } else {
        errorMessage = res.message;
      }
    })
    .finally(() => {
      loading = false;
    });
  }

  function savePage() {
    loading = true;
    let tempPage = copyObject(page);
    tempPage.status = status;
    if (page.id !== 0) {
      method = "PUT";
      urlPart = "edit"
    }
    fetchApi(apiEndpoint + "/admin/page/" + urlPart, tempPage, method)
    .then(res => {
      if (res.status) {
        navigate("/page/list/edit/"+res.data.id);
      } else {
        errorMessage = res.message;
      }
    })
    .finally(() => {
      loading = false;
    });
  }
</script>

<div class="content" id="page-page-add" in:fade>
    <div class="card plr">
      {#if id && id !== ""}
        <h1>Edit Page</h1>
      {:else}
        <h1>Add New Page</h1>
      {/if}
    </div>
    <div class="horizontal flex-same">
      <div class="edit-form card">
        <label>Title
          <input type="text" bind:value={page.title}>
        </label>
        <label>Slug
          <input type="text" bind:value={page.slug}>
        </label>
        <label>Description
          <textarea rows="6" bind:value={page.description}></textarea>
        </label>
        <label>Content
<!--          <textarea rows="15" bind:value={page.content}></textarea>-->
        </label>
        <div id="editor"></div>
      </div>
      <div class="actions card" in:fade>
        {#if loading}
          <Spinner />
        {/if}
        <ErrorMessage errorMessage={errorMessage}/>
        <label>Status
          <select bind:value={status}>
            <option value="draft">draft</option>
            <option value="published">publish</option>
            <option value="deleted">trash</option>
          </select>
        </label>
        <button class="light-green bold pointer" on:click={savePage} disabled={loading}>Save</button>
        {#if page.status !== ""}
          {#if page.status !== "deleted"}
            <button class="red bold pointer" on:click={() => changeStatus("soft-delete")} disabled={loading}>Trash</button>
          {:else}
            <button class="green bold pointer" on:click={() => changeStatus("recover")} disabled={loading}>Recover</button>
            <button class="red bold pointer" on:click={() => changeStatus("delete")} disabled={loading}>Permanently Delete</button>
          {/if}
          {#if page.status === "published"}
            <a href="{env.baseUrl}/p/{page.slug}" target="_blank">{$_.t("recipe:View")}</a>
          {/if}
        {/if}
      </div>
    </div>
    <ErrorMessage errorMessage={errorMessage} />
</div>