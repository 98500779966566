<script>
  import {fade} from 'svelte/transition';
  import {onMount} from "svelte";
  import moment from "moment";
  import Spinner from "svelte-spinner"
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import {navigate} from "svelte-routing"
  import PublicImage from "../../ui/PublicImage.svelte";
  import {_} from '../../../store/i18n';
  import {user} from "../../../store/user";
  import { toast } from '@zerodevx/svelte-toast'

  export let pageNumber = 1;
  let loading, errorMessage;
  let recipes = [];
  let nullDate = "0001-01-01T00:00:00Z";
  let statusFilter = "";
  let userFilter;
  let translatorFilter;
  let translateEditorFilter;
  let users = [];
  let meta = {
    page: 1,
    per_page: 10,
    total: 0
  };

  $: {
    statusFilter;
    translatorFilter;
    translateEditorFilter;
    userFilter;
    pageNumber = parseInt(pageNumber);
    loadRecipes(pageNumber);
  }

  onMount(() => {
    loadUsers();
  });

  function loadRecipes(page) {
    loading = true;
    meta.page = parseInt(page);
    fetchApi(apiEndpoint + "/admin/recipe/get/list", {
      status: (statusFilter === "") ? [
        "draft",
        "recipe_review",
        "translate",
        "translate_review",
        "pending",
        "published",
      ] : [statusFilter],
      page: meta.page,
      per_page: meta.per_page,
      user: {
        id: parseInt(userFilter),
      },
      translator: {
        id: parseInt(translatorFilter),
      },
      translate_editor: {
        id: parseInt(translateEditorFilter),
      }
    }, "POST")
    .then(res => {
      if (res.status) {
        recipes = res.data;
        meta.total = res.meta.total;
      } else {
        errorMessage = res.message;
        toast.push(res.message, {
          theme: {
            '--toastBackground': '#F56565',
            '--toastProgressBackground': '#C53030'
          }
        });
      }
    })
    .finally(() => {
      loading = false;
    });
  }

  function loadUsers() {
    fetchApi(apiEndpoint + "/admin/user/get/list", {
      status: ["active"],
      page: 1,
      per_page: 20,
    }, "POST")
    .then(res => {
      if (res.status) {
        users = res.data;
      } else {
        errorMessage = res.message;
        toast.push(res.message, {
          theme: {
            '--toastBackground': '#F56565',
            '--toastProgressBackground': '#C53030'
          }
        });
      }
    })
    .finally(() => {
      loading = false;
    });
  }

  function canView(recipe) {
    if (
      $user.is("admin")
        || ($user.is("author") && recipe.status === "draft" && $user.id === recipe.user_id)
        || ($user.is("author") && recipe.status === "recipe_review" && $user.id === recipe.user_id)
        || ($user.is("translator") && recipe.status === "translate" && $user.id === recipe.translator_id)
        || ($user.is("recipe_editor") && recipe.status === "recipe_review")
        || ($user.is("translate_editor") && recipe.status === "translate_review" && $user.id === recipe.translate_editor_id)
    ) {
      return true;
    }
    return false;
  }

  function getDate(d) {
    if (d === nullDate) {
      return "-";
    }
    return moment(d).format("D, M, YYYY  hh:mm");
  }

  function handleClick(e, recipe) {
    if (!canView(recipe)) {
      e.preventDefault();
      return;
    }
  }
</script>

<div class="content" id="page-recipe-list" in:fade>
  <div class="card plr">
    <h1>{$_.t("recipe-list:Recipe List")}</h1>
  </div>
  <div class="card">
    {#if loading}
      <Spinner />
    {:else}
      <div class="filters">
        <div>
          <label class="gray">{$_.t("recipe-list:Status")}</label>
          <select bind:value={statusFilter}>
            <option value="">{$_.t("recipe-list:-")}</option>
            <option value="draft">{$_.t("recipe-list:draft")}</option>
            <option value="recipe_review">{$_.t("recipe-list:recipe_review")}</option>
            <option value="translate">{$_.t("recipe-list:translate")}</option>
            <option value="translate_review">{$_.t("recipe-list:translate_review")}</option>
            <option value="pending">{$_.t("recipe-list:pending")}</option>
            <option value="published">{$_.t("recipe-list:published")}</option>
          </select>
        </div>
        <div>
          <label class="gray">{$_.t("recipe-list:Author")}</label>
          <select bind:value={userFilter}>
            <option value="0">{$_.t("recipe-list:-")}</option>
            {#each users as user}
              {#if user.roles.includes("author")}
                <option value={user.id}>{user.first_name} {user.last_name}</option>
              {/if}
            {/each}
          </select>
        </div>
        <div>
          <label class="gray">{$_.t("recipe-list:Translator")}</label>
          <select bind:value={translatorFilter}>
            <option value="0">{$_.t("recipe-list:-")}</option>
            {#each users as user}
              {#if user.roles.includes("translator")}
                <option value={user.id}>{user.first_name} {user.last_name}</option>
              {/if}
            {/each}
          </select>
        </div>
        <div>
          <label class="gray">{$_.t("recipe-list:Editor")}</label>
          <select bind:value={translateEditorFilter}>
            <option value="0">{$_.t("recipe-list:-")}</option>
            {#each users as user}
              {#if user.roles.includes("translate_editor")}
                <option value={user.id}>{user.first_name} {user.last_name}</option>
              {/if}
            {/each}
          </select>
        </div>
      </div>
      {#if recipes.length > 0}
      <div class="item-list">
        <div class="item heading">
          <div class="thumb">{$_.t("recipe-list:Ph")}</div>
          <div class="title">{$_.t("recipe-list:Title")}</div>
          <div>{$_.t("recipe-list:Status")}</div>
          <div>{$_.t("recipe-list:Published")}</div>
        </div>
        {#each recipes as recipe, i (recipe.id)}
          {#if recipe !== null}
            <a class="item" target="_blank" href="/recipe/list/edit/{recipe.id}" class:gray={!canView(recipe)} on:click={(e) => handleClick(e, recipe)}>
              <div class="thumb">
                {#if recipe.images && recipe.images[0]}
                  <PublicImage uuid={recipe.images[0].uuid} size="1x1-m" />
                {/if}
              </div>
              <div class="title">
                <div class="bold" class:gray={!canView(recipe)}>{recipe.title} {#if $user.is("admin") && recipe.is_imported}&reg;{/if}</div>
                <small class="gray">{recipe.user.user_name}</small>
              </div>
              <div>
                <div class="chips"
                     class:gray={recipe.status === "draft"}
                     class:yellow={recipe.status === "recipe_review"}
                     class:orange={recipe.status === "translate"}
                     class:blue={recipe.status === "translate_review"}
                     class:purple={recipe.status === "pending"}
                     class:green={recipe.status === "published"}>{$_.t("recipe-list:"+recipe.status)}</div>
              </div>
              <div>{getDate(recipe.published_at)}</div>
            </a>
          {/if}
        {/each}
      </div>
      {:else}
        <p>No recipe yet!</p>
      {/if}
    {/if}
    <div class="list-pagination">
      {#if meta.page !== 1}
        <div class="chips" on:click={() => navigate("/recipe/list/page/"+(meta.page-1))}>{$_.t("pagination:Prev")}</div>
      {:else}
        <div class="chips inactive">{$_.t("pagination:Prev")}</div>
      {/if}
      {#if meta.total > (meta.page*meta.per_page)}
        <div class="chips" on:click={() => navigate("/recipe/list/page/"+(meta.page+1))}>{$_.t("pagination:Next")}</div>
      {:else}
        <div class="chips inactive">{$_.t("pagination:Next")}</div>
      {/if}
      <div class="gray"><small>{$_.t("pagination:Page")}: {meta.page}</small></div>
    </div>
    <ErrorMessage errorMessage={errorMessage} />
  </div>
</div>