<script>
    import { publicStorage } from "../../lib/api";

    export let uuid = "";
    export let size = "1x1-m";
    export let classAttr;
    export let altAttr = "";
</script>

{#if uuid !== ""}
	<img on:click
		 class={classAttr}
		 src="{publicStorage}/image/{uuid.substring(0,2)}/{uuid.substring(2,4)}/{uuid}-{size}.jpg"
		 alt={altAttr} />
{:else}
	<img class={classAttr} src="/build/img/def.png" alt="upload">
{/if}