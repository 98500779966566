<script>
  import {fade} from 'svelte/transition';
  import {onMount} from "svelte";
  import moment from "moment";
  import Spinner from "svelte-spinner"
  import {apiEndpoint, fetchApi} from "../../../lib/api";
  import ErrorMessage from "../../ui/ErrorMessage.svelte";
  import {navigate} from "svelte-routing"
  import PublicImage from "../../ui/PublicImage.svelte";
  import {_} from '../../../store/i18n';

  let loading, errorMessage;
  let recipes = [];
  let nullDate = "0001-01-01T00:00:00Z";

  onMount(() => {
      loadRecipes();
  });

  function loadRecipes() {
    loading = true;
    fetchApi(apiEndpoint + "/admin/recipe/get/list", {
      status: ["deleted"],
    }, "POST")
    .then(res => {
      if (res.status) {
        recipes = res.data;
        console.log("recipes", recipes);
      } else {
        errorMessage = res.message;
      }
    })
    .finally(() => {
      loading = false;
    });
  }

  function getDate(d) {
    if (d === nullDate) {
      return "-";
    }
    return moment(d).format("D, M, YYYY  hh:mm");
  }
</script>

<div class="content" id="page-recipe-list" in:fade>
  <div class="card plr">
    <h1>{$_.t("recipe-list:Trash List")}</h1>
  </div>
  <div class="card">
    {#if loading}
        <Spinner />
    {:else}
      {#if recipes.length > 0}
      <div class="item-list">
        <div class="item heading gray">
          <div class="thumb">{$_.t("recipe-list:Ph")}</div>
          <div class="title">{$_.t("recipe-list:Title")}</div>
          <div>{$_.t("recipe-list:Status")}</div>
          <div>{$_.t("recipe-list:Published")}</div>
        </div>
        {#each recipes as recipe, i (recipe.id)}
          <div class="item" on:click={() => navigate("/recipe/trash/edit/"+recipe.id)}>
            <div class="thumb">
              {#if recipe.images && recipe.images[0]}
                <PublicImage uuid={recipe.images[0].uuid} size="1x1-m" />
              {/if}
            </div>
            <div class="title">
              <div class="bold">{recipe.title}</div>
              <small class="gray">{recipe.user.user_name}</small>
            </div>
            <div>
             <div class="chips" class:red={recipe.status === "deleted"}>{$_.t("recipe-list:"+recipe.status)}</div>
            </div>
            <div>{getDate(recipe.published_at)}</div>
          </div>
        {/each}
      </div>
      {:else}
        <p>No recipe yet!</p>
      {/if}
    {/if}
      <ErrorMessage errorMessage={errorMessage} />
  </div>
</div>